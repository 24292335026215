//imports
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Popover } from 'react-tiny-popover';
import { disableIdleLockSwitch, enableIdleLockSwitch, displayNotice, RootState } from '../../../../globals';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro"
import { Column } from '../../../../components/commonTable';
import { ScheduleDTO } from '../../../../models/ScheduleDTO';
import { DataInfoModal, ItemCount, Page } from './interfaces';

//services
import HttpManager from '../../../../services/HttpManager';

//view
import AttendancesView from './attendancesView';
import { scheduleStatus } from '../../../../constants';

//messages
const noStudentsForSchedule = "No hay estudiantes registrados para este horario";
const noCampuses = "No hay planteles registrados o asignados al rol en uso por el usuario, por lo que el acceso a este módulo permanecerá deshabilitado.";
const initError = "La búsqueda iniclal de datos no pudo ser completada.";
const unlinkedClassroomsFoundMessage = "Algunos horarios pertenecen a salones sin edificio o periodo asociado, o el periodo asociado no pertenece a ningún plantel, " + "por lo que no se podrá acceder a esta sección.";

//constants
const pagesSize: number[] = [10, 20, 50, 100];

const AttendancesController = () => {

    //init dispatch
    const dispatch = useDispatch();

    //init http
    const http = HttpManager.getInstance();

    //useSelector
    const sessionToken: string | null = useSelector((state: RootState) => state.sessionToken.value);
    const currentUserCampus: any = useSelector((state: RootState) => state.currentUserCampus.value);
    const userProfile: any = useSelector((state: RootState) => state.userProfile.value);
    const campusRef: any = useSelector((state: RootState) => state.campusRef.value);

    //useState
    const [sectionLoaded, setSectionLoaded] = useState<boolean>(false);
    const [schedules, setSchedules] = useState<ScheduleDTO[] | []>([]);
    const [userCampus, setUserCampus] = useState<number>(currentUserCampus);
    const [selectedSchedule, setSelectedSchedule] = useState<ScheduleDTO | null>(null);
    const [noStudentsEnrolledForSchedule, setNoStudentsEnrolledForSchedule] = useState<number>(0);
    const [showInfoModal, setShowInfoModal] = useState<boolean>(false);
    const [dataInfoModal, setDataInfoModal] = useState<DataInfoModal>({});
    const [itemCount, setItemCount] = useState<ItemCount>({
        start: 0,
        end: 0,
        total: 0
    });
    const [pages, setPages] = useState<Page[]>([
        {
            "url": null,
            "label": "&laquo; Previous",
            "active": false
        },
        {
            "url": "",
            "label": "1",
            "active": true
        },
        {
            "url": null,
            "label": "Next &raquo;",
            "active": false
        }
    ]);
    const [itemsPerPage, setItemsPerPage] = useState<number>(pagesSize[0]);

    //useEffect
    useEffect(() => {
        if (sessionToken) {
            currentUserCampus == null ? displayError(noCampuses) : initFn();
        }
    }, []);

    useEffect(() => {
        if ((sectionLoaded && currentUserCampus) && (currentUserCampus != userCampus)) {
            dispatch(enableIdleLockSwitch());
            setSectionLoaded(false);
            localStorage.setItem("currentCampus", currentUserCampus)
            setUserCampus(currentUserCampus)
            setSelectedSchedule(null)
            setSchedules([]);
            initFn();
        }
    }, [currentUserCampus, sectionLoaded]);

    useEffect(() => {
        if (sessionToken) {
            getSchedules();
        }
    }, [itemsPerPage]);

    const updatePaginationInfo = (pagination: any) => {
        const updatedPages = pagination.links.map((page: any) => ({
            ...page,
            onClick: () => {
                if (page.url) {
                    getSchedules(page.url);
                }
            }
        }));
        setItemCount({
            start: pagination.from,
            end: pagination.to,
            total: pagination.total
        });
        setPages(updatedPages);
    }

    //consts
    const columns: Column[] = [
        {
            id: "attendance",
            header: <FontAwesomeIcon icon={solid("clipboard-user")} style={{ color: "#455560" }} />,
            accessor: "",
            render: (schedule: ScheduleDTO) => {
                return (
                    <Popover
                        positions={["top"]}
                        isOpen={noStudentsEnrolledForSchedule == schedule.scheduleId}
                        content={
                            <div style={{ background: "var(--main-bg)", padding: "5px" }}>
                                <div className="requirementAlert">
                                    <FontAwesomeIcon icon={solid("exclamation")} />
                                </div>
                                &nbsp;
                                {noStudentsForSchedule}
                            </div>
                        }>
                        <FontAwesomeIcon icon={solid("clipboard-user")} className={schedule.hasEnrollments ? "" : "disabled"} style={{ color: schedule.hasEnrollments ? "#349dff" : "#c9c9c9" }} />
                    </Popover>
                )
            }
        },
        {
            id: "name",
            header: "Nombre",
            accessor: "name"
        },
        {
            id: "course",
            header: "Curso",
            accessor: "course"
        },
        {
            id: "code",
            header: "Código",
            accessor: "code"
        },
        {
            id: "level",
            header: "Módulo",
            accessor: "level"
        },
        {
            id: "period",
            header: "Periodo",
            accessor: "period"
        },
        {
            id: "mentor",
            header: "Profesor",
            accessor: "mentor"
        },
        {
            id: "building",
            header: "Edificio",
            accessor: "building"
        },
        {
            id: "classroom",
            header: "Aula",
            accessor: "classroom"
        },
        {
            id: "sessions",
            header: "Sesiones",
            accessor: "",
            render: (schedule: ScheduleDTO) => {
                return schedule.sessions.split(',').map((session: string) => {
                    return (
                        <span key={session} style={{ display: "block" }}>
                            {
                                session
                            }
                        </span>
                    );
                })
            }
        },
        {
            id: "status",
            header: "Estatus",
            accessor: "",
            render: (schedule: ScheduleDTO) => {
                return (
                    <span style={{ color: schedule.status === 'Activo' ? "#28a745" : "#de1f21" }}>
                        {
                            schedule.status
                        }
                    </span>
                );
            }
        },
        {
            id: "startDate",
            header: "Inicio",
            accessor: "startDate"
        },
        {
            id: "endDate",
            header: "Fin",
            accessor: "endDate"
        },
        {
            id: "billableMonths",
            header: "Meses a facturar",
            accessor: "billableMonths"
        },
    ];

    //functions
    const initFn = () => {
        getSchedules();
    };

    const getSchedules = async (url?: string) => {
        try {
            dispatch(enableIdleLockSwitch());
            setSelectedSchedule(null)
            setSchedules([]);

            const criteria: object | string = userProfile.currentRole == "mentor"
                ? { "mentor": userProfile.id, "statusId": scheduleStatus.ACTIVE }
                : "";
            const result: any = url
                ? await http.scheduleService.getPaginatedSchedules(url, criteria)
                : await http.scheduleService.getSchedulesByCampusId(campusRef[currentUserCampus].id, itemsPerPage, criteria);
            const status = result.status;

            if (status == 200 || status == 204) {
                let unlinkedClassroomsFound: boolean = false;

                let schedulesData = result.data.data.map((schedule: ScheduleDTO) => {
                    if (schedule.building == null || schedule.period == null) {
                        unlinkedClassroomsFound = true
                        return;
                    }
                    return schedule
                });

                setSchedules(schedulesData);
                updatePaginationInfo(result.data)

                if (unlinkedClassroomsFound) {
                    displayError(unlinkedClassroomsFoundMessage);
                } else {
                    setSectionLoaded(true);
                }
            } else {
                displayError(initError)
            }
        }
        catch (error) {
            console.log(error);
            displayError(initError)
        } finally {
            dispatch(disableIdleLockSwitch())
        }
    }

    const displayError = (message: string) => {
        dispatch(
            displayNotice({
                cornerClose: false,
                message: message,
                heading:
                    <h3 style={{ color: "#FF0000", display: "inline-block" }}>
                        Error
                    </h3>
            })
        );
    };

    const miniMessage = (header: string, message: string) => {
        setDataInfoModal({
            title: header,
            content: (
                <span>
                    {message}
                </span>
            )
        })
        setShowInfoModal(true);
    }


    const closeInfoModal = () => {
        setShowInfoModal(false);
        setDataInfoModal({});
    }

    const handlePageSizeChange = (newPageSize: number) => {
        setItemsPerPage(newPageSize);
    }

    const scheduleClick = (schedule: ScheduleDTO) => {
        if (schedule.hasEnrollments) {
            setSelectedSchedule(schedule);
        } else {
            setNoStudentsEnrolledForSchedule(schedule.scheduleId)
            setTimeout(() => setNoStudentsEnrolledForSchedule(0), 2000)
        }
    }

    return (
        <AttendancesView
            sectionLoaded={sectionLoaded}
            schedules={schedules}
            setSchedules={setSchedules}
            selectedSchedule={selectedSchedule}
            setSelectedSchedule={setSelectedSchedule}
            displayError={displayError}
            columns={columns}
            itemsPerPage={itemsPerPage}
            handlePageSizeChange={handlePageSizeChange}
            pages={pages}
            pagesSize={pagesSize}
            itemCount={itemCount}
            showInfoModal={showInfoModal}
            dataInfoModal={dataInfoModal}
            closeInfoModal={closeInfoModal}
            miniMessage={miniMessage}
            scheduleClick={scheduleClick}
        />
    );
}

export default AttendancesController