import React from "react"
import ReactDOM from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import { Provider } from "react-redux"
import store, { persistor } from "./globals"
import App from "./app"
import reportWebVitals from "./reportWebVitals"

import "bootstrap/dist/css/bootstrap.min.css"
import "typeface-roboto"
import "./assets/css/theme.css"
import "./assets/css/style.css"
import "./style.css"
import "./index.scss"

import { enableMapSet } from 'immer';
import { PersistGate } from "redux-persist/integration/react"
import ErrorBoundary from "./errorBoundary"

enableMapSet();

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render
	(
		<React.StrictMode>
			<Provider store={store}>
				<PersistGate loading={<div>Cargando...</div>} persistor={persistor}>
					<BrowserRouter>
						<ErrorBoundary>
							<App />
						</ErrorBoundary>
					</BrowserRouter>
				</PersistGate>
			</Provider>
		</React.StrictMode >
	)

reportWebVitals()
