import React, { Component, ErrorInfo, ReactNode } from "react";

interface Props {
    children: ReactNode;
}

interface State {
    hasError: boolean;
    error?: Error;
    errorInfo?: ErrorInfo;
}

class ErrorBoundary extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { hasError: false, error: undefined, errorInfo: undefined };
    }

    static getDerivedStateFromError(error: Error) {
        return { hasError: true, error };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error("Error atrapado por el ErrorBoundary:", error, errorInfo);
        this.setState({ error, errorInfo });
    }

    handleReset = () => {
        this.setState({ hasError: false, error: undefined, errorInfo: undefined });
        window.location.reload(); // restart app
    };

    render() {
        if (this.state.hasError) {
            return (
                <div style={{ textAlign: "center", padding: "20px" }}>
                    <h2>Ocurrió un error. Por favor, intenta recargar la página.</h2>
                    {this.state.error && <p style={{ color: "red" }}>{this.state.error.message}</p>}
                    {this.state.errorInfo && (
                        <pre
                            style={{
                                backgroundColor: "#f8d7da",
                                color: "#721c24",
                                padding: "10px",
                                borderRadius: "5px",
                                textAlign: "left",
                                maxWidth: "600px",
                                margin: "20px auto",
                                overflowX: "auto",
                            }}
                        >
                            {this.state.errorInfo.componentStack}
                        </pre>
                    )}
                    <button onClick={this.handleReset} style={{ padding: "10px 20px", cursor: "pointer" }}>
                        Recargar Aplicación
                    </button>
                </div>
            );
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
