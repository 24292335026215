import
{
	useRef,
	useState,
	useEffect
}
	from "react"
import
{
	Route,
	Routes,
	Navigate,
	Link,
	useLocation,
	useNavigate
}
	from "react-router-dom"
import Modal
	from "react-modal"
import
{
	useSelector,
	useDispatch
}
	from "react-redux"
import {Popover}
	from "react-tiny-popover"
import {FontAwesomeIcon}
	from "@fortawesome/react-fontawesome"
import
{
	solid,
	regular
}
	from "@fortawesome/fontawesome-svg-core/import.macro"
import _
	from "lodash"

import
store, {
	RootState,

	displayNotice,
	dismissNotice,

	enableIdleLockSwitch,
	disableIdleLockSwitch,

	setSessionToken,
	setAuthenticated,
	toggleTheme,
	setUserProfile,
	setCurrentUserRole,
	setCurrentUserCampus,
	setCurrentUserCampusId,
	setCampusRef,

	setUserMenu,
	setMenuRef,
	setNavigation
}
	from "./globals"
import useClickOutsideRef,
{
	themes,
	UIState,
	baseNavigation,
	basicSetup,
	baseMenu,
	isNotEmpty,
	generateSectionMap
}
	from "./common"
import AuthService
	from "./services/auth.service"
import CustomIcon
	from "./components/customIcon"
import {UserPrompt}
	from "./components/sectionContainer"
import Access
	from "./navigation/access"
import defaultUserImage
	from "./assets/images/user.png"
import logoA
	from "./assets/images/logoA.png"
import logoB
	from "./assets/images/logoB.png"

import "./style.css"
import "./assets/css/app.css"
import {tunning} from "./constants"
import {
	setCurrentCampusId, 
	setCurrentRole, 
	setAllowedCampuses,
	setCurrentCampus,
	setUser,
	UserState,
	handleLogout
} from "./redux/reducers/sessionReducer";
import HttpManager from "./services/HttpManager"
import { setPaymentMethods } from "./redux/reducers/catalogReducer"
import { useAxiosInterceptor } from "./services/v2/axiosInstance"
import { stat } from "fs"


const PrivateRoute = ({authenticated, children} : {authenticated : boolean; children : any})=>
{
	if(!authenticated)
	{
		return <Navigate to="/access" replace />
	}

	return children
}

const Accordion=
(
	{block, content, currentRoute} : {block  : any; content : {display : string; route : string}[]; currentRoute : string}
)=>
{
	const [isActive, setIsActive] = useState(false)
	const icons   : any           = [solid("chevron-up"), solid("chevron-down")]
	const menuRef : any           = useSelector((state : RootState) => state.menuRef.value)
	const idleLocked    : boolean = useSelector((state : RootState) => state.idleLockSwitch.value)

	return <tbody>
		<tr onClick={() => setIsActive(!isActive)} style={{background : "#141535", color : "#8F91DA", cursor : "pointer"}}>
			<td style={{width : "75px", textAlign : "right", color : "#5154AE"}} className="xsDisposable">
				<CustomIcon name={block.icon} style={{fill : "#8F91DA"}}/>
			</td>
			<td style={{padding : "5px 0px 5px 10px", color : (menuRef[currentRoute] != block.group ? "#5154AE" : "#8F91DA")}}><b>
				{block.display}
			</b></td>
			<td className="xsDisposable">
				<FontAwesomeIcon icon={icons[ +isActive ]}/>
			</td>
		</tr>
		{
			!isActive ? "" : content.map
			(
				({display, route} : {display : string; route : string}) => <tr key={block.display + "." + display}
				  style={{background : (route == currentRoute ? "#32345E" : "#141535"), color : "#FFFFFF"}}
				>
					<td style={{textAlign : "right", paddingRight : "5px"}} className="xsDisposable" />
					<td colSpan={2} style={{padding : "5px 20px 0px"}}>{
						idleLocked
								?
							<div style={{fontWeight : route == currentRoute ? "500" : "300"}}>
								{display}
							</div>
								:
							<Link to={route}>
								<div style={{fontWeight : route == currentRoute ? "500" : "300"}}>
									{display}
								</div>
							</Link>
					}</td>
				</tr>
			)
		}
	</tbody>
}

const App = ()=>
{
	useAxiosInterceptor();
	const [dirRef, setDirRef] = useState(false)

	const notificationsSample=
	[
		{
			"icon"    : solid("triangle-exclamation"),
			"content" : "Curabitur id eros quis nunc suscipit blandit"
		},
		{
			"icon"    : solid("boxes-packing"),
			"content" : "Duis malesuada justo eu sapien elementum, in semper diam posuere"
		},
		{
			"icon"    : solid("cart-shopping"),
			"content" : "Donec at nisi sit amet tortor commodo porttitor pretium a erat"
		},
		{
			"icon"    : solid("triangle-exclamation"),
			"content" : "In gravida mauris et nisi"
		},
		{
			"icon"    : solid("cart-shopping"),
			"content" : "Curabitur id eros quis nunc suscipit blandit"
		},
		{
			"icon"    : solid("cart-shopping"),
			"content" : "Curabitur id eros quis nunc suscipit blandit"
		},
		{
			"icon"    : solid("cart-shopping"),
			"content" : "Curabitur id eros quis nunc suscipit blandit"
		},
		{
			"icon"    : solid("cart-shopping"),
			"content" : "Curabitur id eros quis nunc suscipit blandit"
		},
		{
			"icon"    : solid("cart-shopping"),
			"content" : "Curabitur id eros quis nunc suscipit blandit"
		},
		{
			"icon"    : solid("cart-shopping"),
			"content" : "Curabitur id eros quis nunc suscipit blandit"
		},
		{
			"icon"    : solid("cart-shopping"),
			"content" : "Curabitur id eros quis nunc suscipit blandit"
		},
		{
			"icon"    : solid("cart-shopping"),
			"content" : "Curabitur id eros quis nunc suscipit blandit"
		},
		{
			"icon"    : solid("cart-shopping"),
			"content" : "Curabitur id eros quis nunc suscipit blandit"
		},
		{
			"icon"    : solid("cart-shopping"),
			"content" : "Curabitur id eros quis nunc suscipit blandit"
		},
		{
			"icon"    : solid("cart-shopping"),
			"content" : "Curabitur id eros quis nunc suscipit blandit"
		},
		{
			"icon"    : solid("cart-shopping"),
			"content" : "Curabitur id eros quis nunc suscipit blandit"
		},
		{
			"icon"    : solid("cart-shopping"),
			"content" : "Curabitur id eros quis nunc suscipit blandit"
		},
		{
			"icon"    : solid("cart-shopping"),
			"content" : "Curabitur id eros quis nunc suscipit blandit"
		},
		{
			"icon"    : solid("cart-shopping"),
			"content" : "Curabitur id eros quis nunc suscipit blandit"
		},
		{
			"icon"    : solid("cart-shopping"),
			"content" : "Curabitur id eros quis nunc suscipit blandit"
		},
		{
			"icon"    : solid("cart-shopping"),
			"content" : "Curabitur id eros quis nunc suscipit blandit"
		},
		{
			"icon"    : solid("cart-shopping"),
			"content" : "Curabitur id eros quis nunc suscipit blandit"
		},
		{
			"icon"    : solid("cart-shopping"),
			"content" : "Curabitur id eros quis nunc suscipit blandit"
		},
		{
			"icon"    : solid("cart-shopping"),
			"content" : "Curabitur id eros quis nunc suscipit blandit"
		},
		{
			"icon"    : solid("cart-shopping"),
			"content" : "Curabitur id eros quis nunc suscipit blandit"
		},
		{
			"icon"    : solid("cart-shopping"),
			"content" : "Curabitur id eros quis nunc suscipit blandit"
		},
		{
			"icon"    : solid("cart-shopping"),
			"content" : "Curabitur id eros quis nunc suscipit blandit"
		},
		{
			"icon"    : solid("cart-shopping"),
			"content" : "Curabitur id eros quis nunc suscipit blandit"
		},
		{
			"icon"    : solid("cart-shopping"),
			"content" : "Curabitur id eros quis nunc suscipit blandit"
		},
		{
			"icon"    : solid("cart-shopping"),
			"content" : "Curabitur id eros quis nunc suscipit blandit"
		}
	];	
	
	const http = HttpManager.getInstance();
	const dispatch          : any                               = useDispatch()
	const navigate          : any                               = useNavigate()
	const currentCampusId: number = useSelector((state: RootState) => state.rootReducer.sessionState.currentCampusId);
	const userData: UserState | null = useSelector((state: RootState) => state.rootReducer.sessionState.user);
	const allowedCampuses: any[] = useSelector((state: RootState) => state.rootReducer.sessionState.allowedCampuses);
	const currentCampus: any = useSelector((state: RootState) => state.rootReducer.sessionState.currentCampus);
	const campuses: any[] = useSelector((state: RootState) => state.rootReducer.catalog.campuses);
	const roles: any[] = useSelector((state: RootState) => state.rootReducer.sessionState.roles);
	
	const userProfile       : any                               = useSelector((state : RootState) => state.userProfile.value)
	const currentUserCampus : any                               = useSelector((state : RootState) => state.currentUserCampus.value)
	const campusRef         : any                               = useSelector((state : RootState) => state.campusRef.value)
	const sessionToken      : string| null                      = useSelector((state : RootState) => state.sessionToken.value)
	const authenticated     : boolean                           = useSelector((state : RootState) => state.authenticated.value)
	const userMenu          : any                               = useSelector((state : RootState) => state.userMenu.value)
	const navigation        : any                               = useSelector((state : RootState) => state.navigation.value)
	const basicInfo         : any                               = useSelector((state : RootState) => state.basicInfo.value)
	const location          : string                            = useLocation().pathname.substr(1)
	const idleLocked        : boolean                           = useSelector((state : RootState) => state.idleLockSwitch.value)
	//const [themeToggle, setThemeToggle] = useState(useSelector((state : RootState) => state.themeToggle.value));
	const [unfilledFields, setUnfilledFields]                   = useState<boolean>(false)
	const [noCityProvided, setNoCityProvided]                   = useState<boolean>(false)
	const [campusesListShown, setCampusesListShown]             = useState<boolean>(false)
	const [appLoaded, loadApp]                                  = useState<boolean>(false)
	const [UIStatus , setUIStatus]                              = useState<number>(UIState.NORMAL)
	const [saveError, setSaveError]                             = useState<any>(null)
	const [roleListShown, roleListDisplay]                      = useState<boolean>(false)
	const [userProfileOptionsShown, setUserProfileOptionsShown] = useState<boolean>(false)
	const [selectedUserProfile, setSelectedUserProfile]         = useState<any>(null)
	const [editableUserProfile, setEditableUserProfile]         = useState<any>(null)
	const [saveSuccess, setSaveSuccess]                         = useState<boolean>(false)
	
	let   formRef                                               = useRef<HTMLFormElement >(null)

	const fetchPaymentMethods = async () => {
		try {
			const response = await http.paymentMethodService.getPaymentMethodsByCountry(currentCampus.countryId);
			dispatch(setPaymentMethods(response.data));
		} catch (err: any) {
			console.error(err);
		}
	}
	
	const handleCampusChange = (campus: any) => {
		dispatch(setCurrentUserCampus(campus.refIndex));
		dispatch(setCurrentUserCampusId(campus.id));
		const campusId = campus.id;
		dispatch(setCurrentCampusId(campusId));
		const newCampus = campuses.find((campus: any) => campus.id === campusId);
		dispatch(setCurrentCampus(newCampus));		
	}

	useEffect(() => {
		if (currentCampus && userData && userData.token) {
			fetchPaymentMethods();
		}
	}, [currentCampusId]);

	const logout = ()=>
	{
		dispatch(handleLogout());
		localStorage.removeItem("persist:root");
		AuthService
			.logout(sessionToken)
			.then
			(
				()=>
				{
					let menu    = baseMenu
					let menuMap = []

					localStorage.clear()
					dispatch(setUserProfile(null))
					dispatch(setCurrentUserCampus(null))
					dispatch(setCurrentUserCampusId(null))
					dispatch(setCampusRef(null))
					dispatch(setSessionToken(null))
					dispatch(setAuthenticated(false))
					dispatch(setUser(null));

					menu?.forEach
					(
						(element : any, index: number)=>
						{
							element.sections.forEach
							(
								(item : any, subIndex : number)=>
								{
									menuMap[item.route] = element.block.group
								}
							)
						}
					)

					dispatch(setUserMenu(baseMenu))
					dispatch(setMenuRef(null))
					dispatch(setNavigation(baseNavigation))
					navigate("/access")
					window.location.reload()
				},
				(error : any)=>
				{
					dispatch
					(
						displayNotice
						({
							heading:
								<h3 style={{color : "#FF0000", display : "inline-block"}}>
									Error
								</h3>,
							message     : error.response.status == 401
								? "Las credenciales proporcionadas no son válidas"
								: "Revise el estado de la red. Refresque la vista para volver a intentar",
							cornerClose : false
						})
					)
				}
			)
			.finally(() => dispatch(disableIdleLockSwitch()))
	}

	const onLoad = async()=>
	{
		let menuMap            : any  = {}
		let assignedNavigation : any
		let menu               : any

		dispatch(enableIdleLockSwitch())

		try
		{
			const accessToken : any = localStorage.getItem("accessToken")

			if(accessToken?.trim())
			{
				dispatch(setSessionToken(accessToken))

				const result       : any           = await AuthService.getInitData()
				let   user         : any           = result.data.user
				let   campuses     : any           = result.data.campuses
				let campusIndex    : any           = {}
				const currentRole  : string | null = localStorage.getItem("currentRole")
				user.currentCampus                 = localStorage.getItem("currentCampus")

				if(currentRole == null)
				{
					if(user.roles.length > 0)
					{
						user.currentRole = user.roles[0].code
					}
					else
					{
						if(user.student)
						{
							user.currentRole = "student"
						}
					}
				}
				else
				{
					if((currentRole == "student" && user.student) || (user.roles.length > 0 && user.roleMap[currentRole]))
					{
						user.currentRole = currentRole
					}
					else
					{
						localStorage.removeItem("currentRole")
					}
				}

				if(user.currentRole)
				{
					dispatch(setCurrentUserRole(user.currentRole))
					localStorage.setItem("currentRole", user.currentRole)					
				}

				let refIndex : string

				if(user.currentRole == "student")
				{
					menu               = baseMenu
					assignedNavigation = baseNavigation

					user.student.campuses.map
					(
						(campus : any)=>
						{
							refIndex              = "C_" + campus.id
							campusIndex[refIndex] = {...campus, refIndex : refIndex}

							return
						}
					)

					user.student.campusRef = campusIndex
				}
				else
				{
					if(user.roleMap[user.currentRole].campuses && user.roleMap[user.currentRole].campuses.length > 0)
					{
						dispatch(setAllowedCampuses(user.roleMap[user.currentRole].campuses));
						user.roleMap[user.currentRole].campuses.map
						(
							(campus : any)=>
							{
								refIndex              = "C_" + campus.id
								campusIndex[refIndex] = {...campus, refIndex : refIndex}

								return
							}
						)
					}
					
					if(user.roleMap[user.currentRole].campuses.length > 0)
					{
						if(user.currentCampus == null || campusIndex[user.currentCampus] == null)
						{
							user.currentCampus = "C_" + user.roleMap[user.currentRole].campuses[0].id
							dispatch(setCurrentCampusId(user.roleMap[user.currentRole].campuses[0].id));
						}
					}
					else
					{
						if(user.currentCampus)
						{
							user.currentCampus = null
						}
					}

					if(user.currentCampus)
					{
						localStorage.setItem("currentCampus", user.currentCampus)		
						dispatch(setCurrentCampusId(user.roleMap[user.currentRole].campuses[0].id));
					}
					else
					{
						if(localStorage.getItem("currentCampus"))
						{
							localStorage.removeItem("currentCampus")
						}
					}

					const [complementaryMenu, complementaryNavigation] = generateSectionMap(user)
					menu                                               = [...baseMenu, ...complementaryMenu]
					assignedNavigation                                 = [...baseNavigation, ...complementaryNavigation]
				}

				dispatch(setCurrentUserCampusId(user.currentCampus ? campusIndex[user.currentCampus].id : null))
				dispatch(setCampusRef({...campusIndex}))
				dispatch(setCurrentUserCampus(user.currentCampus))
				basicSetup(result.data, dispatch)
			}
			else
			{
				menu               = baseMenu
				assignedNavigation = baseNavigation
			}
		}
		catch(error)
		{
			menu               = baseMenu
			assignedNavigation = baseNavigation

			console.log(error)
			localStorage.clear()
		}
		finally
		{
			loadApp(true)
			dispatch(disableIdleLockSwitch())

			menu?.forEach
			(
				(element : any, index: number)=>
				{
					element.sections.forEach
					(
						(item : any, subIndex : number)=>
						{
							menuMap[item.route] = element.block.group
						}
					)
				}
			)

			dispatch(setUserMenu(menu))
			dispatch(setMenuRef(menuMap))
			dispatch(setNavigation(assignedNavigation))			
		}
	}

	useEffect(() => {onLoad()}, [])	

	const saveUserProfile = async()=>
	{
		if(formRef && formRef.current)
		{
			if(!formRef.current.checkValidity())
			{
				setUnfilledFields(true)
				formRef.current.reportValidity()

				setTimeout
				(
					()=>
					{
						setUnfilledFields(false)
					},
					3000
				)
			}
			else
			{
				if
				(
					(
						basicInfo.countryRef["C_" + editableUserProfile.country_id].states.length > 0 &&
						basicInfo.countryRef["C_" + editableUserProfile.country_id].stateRef["S_" + editableUserProfile.state_id].cities.length > 0
					)
						&&
					(editableUserProfile.city == null || editableUserProfile.city.id == null)
				)
				{
					setUnfilledFields(true)
					setNoCityProvided(true)

					setTimeout
					(
						() =>
						{
							setUnfilledFields(false)
							setNoCityProvided(false)
						},
						3000
					)
				}
				else
				{
					if(sessionToken)
					{
						dispatch(enableIdleLockSwitch());

						try
						{
							const result = await AuthService.saveCurrentUser(editableUserProfile)
							setUIStatus(UIState.LOCKED)

							if(result.status == 200 || result.status == 204)
							{
								setSaveSuccess(true)
								setUIStatus(UIState.SUCCESS)

								if(saveError != null)
								{
									setSaveError(null)
								}

								setTimeout
								(
									()=>
									{
										dispatch
										(setUserProfile({...editableUserProfile, image : result.data.image}))
										setSelectedUserProfile(null)
										setEditableUserProfile(null)
										setSaveSuccess(false)
										setUIStatus(UIState.NORMAL)
									},
									tunning.MODAL_DISMISS_DELAY
								)
							}
							else
							{
								setSaveError
								(
									result.status == 409
									?
										"Hay conflictos en la información proporcionada (correo electrónico, código de identificación). " +
											"Revise que los valores sean únicos en comparación con los registros existentes (alumnos, empleados)"
									:
										"La información no pudo ser guardada"
								)
								setUIStatus(UIState.ERROR)
							}
						}
						catch(error : any)
						{
							console.log(error)
							setSaveError
							(
								error.response.status != 409 ? "La información no pudo ser guardada" : "Hay conflictos en la información proporcionada " +
										"(" + (error.response.data.message == "duplicate_email_info" ? "correo electrónico" : "código de identificación") + "). " +
											"Revise que los valores sean únicos en comparación con los registros existentes (alumnos, empleados)"
							)
							setUIStatus(UIState.ERROR)
						}
						finally
						{
							dispatch(disableIdleLockSwitch())
						}
					}
				}
			}
		}
	}

	const themeToggle : boolean = useSelector((state : RootState) => state.themeToggle.value)

	const commonNotice:
		{heading : HTMLHeadingElement | null; message : string | null; procedure? : any; optOutProcedure? : any; cornerClose? : boolean}=
			useSelector((state : RootState) => state.commonNotice)

	const icons=
	[
		regular("moon"),
		solid("sun")
	];

	const modeSettings =
	[
		{
			id         : "light",
			name       : "Light",
			background : "lightBackground",
			class      : "theme-mode-light"
		},
		{
			id         : "dark",
			name       : "Dark",
			background : "dark-background",
			class      : "theme-mode-dark"
		}
	];

	const themeSetting = ()=>
	{
		const toggle = !themeToggle
		const mode   = modeSettings[ +!themeToggle ]

		localStorage.setItem("themeMode", mode.class)
		dispatch(toggleTheme())
	};

	const toggleRoleListDisplay = ()=>
	{
		roleListDisplay(!roleListShown)
	}

	const changeRole = (role : string)=>
	{
		localStorage.setItem("currentRole", role)
		dispatch(setCurrentRole(roles.find((role: any) => role.code === role)));
		window.location.reload()
	}

	const showUserProfile = (recordData : any)=>
	{
		let links : any = isNotEmpty(recordData.links) ? JSON.parse(recordData.links) : {}

		Object.keys(links).map((key : string) => links[key] = links[key] || "")
		setUIStatus(UIState.NORMAL)
		setSaveError(null)
		setSelectedUserProfile
		({
			...links, ...recordData, city : {...recordData.city, label : recordData.city.name},
			zipcode                       : recordData.zipcode || "", 
			imagePreview                  : recordData.image,
		})
	}

	const MenuDivRef    : any = useRef<HTMLDivElement>(null)
	const menuToggleRef : any = useRef<HTMLButtonElement>(null)

	useClickOutsideRef(MenuDivRef, menuToggleRef)

	const setActiveMenu = () => MenuDivRef?.current?.classList?.add("active")
	const closeMenu     = () => MenuDivRef?.current?.classList?.remove("active")

	return (!appLoaded || userMenu == null || navigation == null) ? <></> : <>
		<div className={themes[ +themeToggle ]} style={{color : "var(--txt-color)"}}><div
		  style={{background : "var(--second-bg)", minHeight : "100vh"}}
		>
			{
				!authenticated ? "" : <div>
					<div className="topNav" style={{display : (location == "access" ? "none" : "")}}>
						<div className="topnavRightItem" style={{flexGrow : 1}}>
							<div style={{display: "flex", justifyContent: "left", alignItems: "center", padding : "4px 10px"}}>
								<button ref={menuToggleRef} className="dropdownToggle" onClick={setActiveMenu}
								  type="button" style={{paddingTop : "0px"}}
								>
									<FontAwesomeIcon icon={solid("bars")} style={{color : "#FFFFFF"}} size="2x"/>
								</button>
								{
									currentUserCampus == null ? "" :
									(
										Object.keys(campusRef).length < 2
											?
										<>
											<Popover isOpen={campusesListShown} onClickOutside={() => setCampusesListShown(false)}
											  positions={["bottom"]} content=
											  {<div style={{padding : "10px 5px", minWidth : "250px", background : "var(--main-bg)", left : "43px"}}>
													<div style={{padding : "10px 5px"}}>
														<div className="d-sm-table-cell d-sm-none d-md-none d-lg-none d-xl-none">
															<FontAwesomeIcon
															  icon={solid("check-circle")}
															  style={{marginLeft : "7px"}}
															/>
															<span style={{paddingLeft : "7px"}}>
																{campusRef[currentUserCampus].name}
															</span>
														</div>
													</div>
											  </div>}
											><button style={{color : "#FFFFFF", display : "inline-flex", paddingTop : "0px"}} disabled={idleLocked}
											  className="btn d-sm-table-cell d-sm-none d-md-none d-lg-none d-xl-none" type="button"
											  onClick={() => setCampusesListShown(!campusesListShown)}
											>
												<FontAwesomeIcon icon={solid("school-flag")}
												  style={{marginLeft : "7px"}}
												/>
												<FontAwesomeIcon icon={solid("caret-down")}
												  style={{marginLeft : "7px"}}
												/>
											</button></Popover>
											<span style={{position : "relative", top : "5x"}}
											  className={"d-none d-sm-inline-block"}
											><span style={{display : "grid", margin : "0px 5px"}}
											  className="prompt"
											>
												{campusRef[currentUserCampus].name}
											</span></span>
										</>
											:
										<Popover onClickOutside={() => setCampusesListShown(false)} isOpen={campusesListShown}
										  positions={["bottom"]} content=
										  {<div style={{padding : "10px 5px", minWidth : "250px", background : "var(--main-bg)", left : "43px"}}>
											<div className="d-sm-table-cell d-sm-none d-md-none d-lg-none d-xl-none"
											  style={{paddingBottom : "10px"}}
											>
												<FontAwesomeIcon icon={solid("check-circle")} style={{marginLeft : "7px"}} />
												<span style={{paddingLeft : "7px"}}>
													{campusRef[currentUserCampus].name}
												</span>
											</div>
											<fieldset style={{padding : "5px 10px 0px"}}>
												<legend style={{position : "relative", bottom : "10px"}}><label
												  style={{background : "var(--main-bg)"}}
												>
													Seleccione otro plantel
												</label></legend>
												{
													Object.values(campusRef).map
													(
														(campus : any)=>
														{
															if(currentUserCampus != campus.refIndex)
															{
																return <button style={{width : "100%", textAlign : "left"}}
																  onClick={ ()=> handleCampusChange(campus) }																	
																  className="btn btn-default" key={"CA_" + campus.id}
																  disabled={idleLocked}
																>
																	{campus.name}
																</button>
															}
														}
													)
												}
											</fieldset>
										  </div>}
										><button onClick={() => setCampusesListShown(!campusesListShown)} disabled={idleLocked}
										  type="button" className="btn" style={{paddingTop : "0px"}}
										><span style={{display : "table-row", position : "relative", top : 3}}>
											<span className="d-sm-table-cell d-sm-none d-md-none d-lg-none d-xl-none"
											  style={{color : "#FFFFFF"}}
											>
												<FontAwesomeIcon
												  icon={solid("school-flag")}
												  style={{marginLeft : "7px"}}
												/>
											</span>
											<span className={"d-none d-sm-table-cell"}><span className="prompt"
											  style={{display : "grid", margin : "0px 5px"}}
											>
												{campusRef[currentUserCampus].name}
											</span></span>
											<span style={{display : "table-cell", color : "#FFFFFF", paddingRight : "5px"}}>
												<FontAwesomeIcon icon={solid("caret-down")}
												  style={{marginLeft : "7px"}}
												/>
											</span>
										</span></button></Popover>
									)
								}
								<div ref={MenuDivRef} className="themeMenu">
									<div style={{padding : "25px 10px", display : "flex", alignItems : "stretch", height : "113px"}}>
										<div style={{flexGrow : 1, textAlign : "center"}}>
											<img src={logoA} alt="company logo" style={{maxWidth : "100%"}} />
										</div>
										<div style={{flexGrow : 1,}} className="logoB">
											<img src={logoB} alt="company brand" style={{width : "85%"}} />
										</div>
									</div>
									<div style={{height : "calc(100vh - 175px)", overflowY : "auto"}}><table id="sideMenu"
									  style={{width : "100%"}}
									>{
										userMenu.map
										(
											(path : any, index : number) => <Accordion currentRoute={location} block={path.block}
											  content={path.sections} key={index}
											/>
										)
									}</table></div>
									<table style={{position : "absolute", bottom : "0px", width : "100%"}}><tbody><tr>
										<td style={{background : "#282A5D", textAlign : "center", padding : "0px 16px"}}><button
										  style={{background : "#141535", borderRadius : "100%"}} onClick={themeSetting}
										  className="dropdownToggle" type="button"
										>
											<FontAwesomeIcon icon={icons[ +themeToggle ]} size="2x"
											  style={{color : "#FFFFFF"}}
											/>
										</button></td>
										<td style={{background : "#282A5D", textAlign : "center", width : "100%"}}><button
										  style={{height : "62px", color : "#5154AE"}} className="themeMenuClose" type="button"
										  onClick={closeMenu}
										>
											<FontAwesomeIcon size="xs" style={{position : "relative", bottom : "5px"}}
											  icon={solid("chevron-left")}
											/>
										</button></td>
									</tr></tbody></table>
								</div>
							</div>
						</div>
						{/*<div className="topNavSearch">
							<FontAwesomeIcon style={{color : "#000000", background : "#FFFFFF", padding : "10px"}}
							  icon={solid("magnifying-glass")}
							/>
							<input  placeholder="Búsqueda..." type="text"/>
						</div>*/}
						<div className="topNavRight">
							<div className="topnavRightItem" style={{padding : "0px 15px 10px 0px"}}>
								{/*<Dropdown renderFooter={() => <Link to="/notifications">Ver todo</Link>}
								  disabled={idleLocked} style={{zIndex : 1}} badge="12" icon={regular("bell")}
								>
									<div style={{padding : "10px"}}>
										<b>
											Notificaciones
										</b>
										<table style={{width : "100%"}}>
											<tbody>
												<tr>
													<td>
														<span className="badge bg-primary">
															Todas
														</span>
													</td>
													<td style={{width : "50%"}}>
														Sin leer
													</td>
													<td>
														<Link to="/notifications">
															Ver todo
														</Link>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
									<div style={{height : "35vh", overflowY : "auto"}}>
										<div style={{padding : "5px"}}>
											<table style={{width : "100%"}}>
												<tbody>
													{
														notificationsSample.map
														(
															(item : any, index : number)=>
															<tr className="notificationItem" key={index}>
																<td style=
																  {{
																	backgroundColor : "#AAB0BB",
																	width           : "35px",
																	height          : "58px",
																	padding         : "15px 5px",
																	borderRadius    : "5px 0px 0px 5px",
																	border          : "2px solid #AAB0BB",
																	color           : "var(--main-txt-color)"
																  }}
																>
																	<FontAwesomeIcon icon={item.icon}/>
																</td>
																<td style={{padding : "1px 0px"}}>
																	<div style={{width : "300px", borderRadius : "0px 5px 5px 0px", border : "1px solid #AAB0BB"}}>
																		<div style={{paddingLeft : "5px"}}>
																			Tipo de notificación
																			<label style={{float : "right", fontSize : "10px", paddingRight : "5px", position : "unset"}}>
																				11:59
																			</label>
																		</div>
																		<div style={{padding : "4px 10px", whiteSpace : "nowrap", overflow : "hidden", textOverflow : "ellipsis"}}>
																			{item.content}
																		</div>
																	</div>
																</td>
															</tr>
														)
													}
												</tbody>
											</table>
										</div>
										<Link to="/notifications">
											<div className="dropdownFooter">
												Ver todo
											</div>
										</Link>
									</div>
								</Dropdown>*/}
							</div>
							<div className="topnavRightItem" style={{paddingBottom : "10px"}}><Popover positions={["bottom"]}
							  onClickOutside={() => setUserProfileOptionsShown(false)} isOpen={userProfileOptionsShown}
							  reposition={false} content=
							  {<div className="card" style=
							  {{background : "var(--main-bg)", padding : "5px 5px 0px", position : "absolute", right : "0px", width : "250px"}}
							  >
								<div className="dropdownFooter">
									Usuario
								</div>
								<div style={{display : "flex", flexDirection : "column", alignItems : "center", justifyContent : "center"}}>
									<div style={{position : "relative"}}>
										<img style={{width : "160px", height : "160px", borderRadius : "50%"}} alt="user photo"
										  src={userProfile.image || defaultUserImage}
										/>
									</div>
									<b style={{fontSize : "12px", marginTop : "15px"}}>
										{userProfile.first_name}
										&nbsp;
										{userProfile.last_name}
									</b>
									<div style={{fontSize: "10px", color : "var(--txt-secondary-color)", width : "75%"}}>
										ID DE USUARIO
									</div>
									<b style={{fontSize: "12px"}}>
										{userProfile.id}
									</b>
								</div>
								{/*
									userProfile.roles.length > 0 &&
									<div>
										userProfile.roleMap[userProfile.currentRole]
									</div>
								*/}
								{
									userProfile.currentRole == null ? "" : <div className="card card-default" style={{padding : "15px"}}>
										<div className="badge rounded-pill bg-primary">{
											(userProfile.currentRole == "student" && "Estudiante")
												|| userProfile.roleMap[userProfile.currentRole].name
										}</div>
										{
											(
												userProfile.roles.length < 1 ||
													(userProfile.student == null && userProfile.roles.length < 2)
											)
												? "" : <button disabled={idleLocked} className="btn btn-default" type="button"
											  style={{color : "var(--txt-main-color)", width : "100%", textAlign : "left", paddingLeft : "20%"}}
											  onClick={toggleRoleListDisplay}
											>
												Cambiar rol
											</button>
										}
									</div>
								}
								{
									userProfile.roleMap["superAdmin"] ? "" : <button className="btn" disabled={idleLocked}
									  style={{color : "var(--txt-main-color)", width : "100%", textAlign : "left", paddingLeft : "20%"}}
									  type="button" onClick=
									  {
										()=>
										{
											setUserProfileOptionsShown(false)
											showUserProfile
											(
												userProfile.currentRole != "student"
													? {...userProfile, ...userProfile.employee}
														: {...userProfile, ...userProfile.student}
											)
										}
									  }
									>
										<FontAwesomeIcon icon={solid("eye")}/>
										&emsp;
										Perfil
									</button>
								}
								<button style={{color : "#FF0000", width : "100%", textAlign : "left", paddingLeft : "20%"}}
								  onClick={logout} disabled={idleLocked} className="btn" type="button"
								>
									<FontAwesomeIcon icon={regular("circle-xmark")} />
									&emsp;
									Cerrar sesión
								</button>
							  </div>}
							><div className="topNavRightUser"><button className="topNavRightUserImage" disabled={idleLocked}
							  type="button" onClick={() => setUserProfileOptionsShown(!userProfileOptionsShown)}
							>
								<img src={userProfile.image || defaultUserImage} style={{height : "100%"}} alt="user picture" />
							</button></div></Popover></div>
						</div>
					</div>
					{
						!idleLocked ? "" : <div className="loadingBar"><div style={{padding : "5px 25px"}}>
							<div className="loader0" style={{borderRadius : "15px"}} />
						</div></div>
					}
				</div>
			}
			<div className="layoutContentMain" style={{height : "calc(100vh - 60px", overflowY : "auto", overflowX : "hidden", width : "100vw"}}><Routes><Route
			  key={"access"} path={"/access"} element={ <Access /> }
			/>{
				navigation && navigation.map
				(
					({path, element : Component} : any)=>
					{
						return<Route key={path} path={"/" + path} element={<PrivateRoute authenticated={authenticated}>
							<Component />
						  </PrivateRoute>}
						/>
					}
				)
			}<Route key="*" path="/*" element={<Navigate to={authenticated ? "/" : "/access"} />} /></Routes></div>
		</div></div>
		{
			selectedUserProfile == null ? "" : <UserPrompt promptCloseFn={() => setSelectedUserProfile(null)} UIStatus={UIStatus}
			  statusIndex={userProfile.currentRole != "student" ? "employeeStatusRef" : "studentStatusRef"} profileView={true}
			  processingError={saveError} selectedUser={selectedUserProfile} allowedUpdate={true} saveSuccess={saveSuccess}
			  uneditFn={() => setEditableUserProfile(null)} saveDataFn={saveUserProfile} title="Perfil de usuario"
			  employeeView={userProfile.currentRole != "student"} icon={<FontAwesomeIcon icon={solid("id-badge")} />}
			  unfilledFields={unfilledFields} dataEditFn=
			  {
				()=>
				{
					setEditableUserProfile
					({
						...selectedUserProfile, state_id : selectedUserProfile.city.state_id,
						country_id                       : selectedUserProfile.city.state.country_id,
						city://Format requirement of the select component
						{
							id    : selectedUserProfile.city.id,
							label : selectedUserProfile.city.name
						}
					})
				}
			  }
			/>
		}
		{
			!roleListShown ? "" : <Modal appElement={document.getElementById("root") as HTMLElement} className={themes[ +themeToggle ]}
			  style=
			  {{
				overlay : {zIndex : 100},
				content : {height : "min-content", background : "var(--main-bg)", borderRadius : "15px"}
			  }} isOpen
			>
				<div style={{padding : "12px 15px 7px 15px"}}><div style={{display : "table-row"}}>
					<div style={{display : "table-cell", color : "var(--txt-color)", paddingTop : "5px", width : "100%"}}>
						Cambiar rol
					</div>
					<div style={{display : "table-cell"}}><button onClick={toggleRoleListDisplay} type="button"
					  disabled={idleLocked || saveSuccess} className="button btn btn-default" style=
					  {{
						color        : "var(--txt-color)",
						background   : "var(--main-bg)",
						borderRadius : "50%",
						width        : "40px",
						border       : "1px solid #CCCCCC",
						padding      : "2px 0px 0px 2px"
					  }}
					>
						<FontAwesomeIcon icon={solid("times")} size="2x" />
					</button></div>
				</div></div>
				<div style={{width : "100%", padding : "unset", maxHeight : "calc(90vh - 4em)"}}><div
				  style={{padding : "30px 30px 10px", overflowY : "auto"}}
				>
					Rol actual:
					<>
						<div className="badge rounded-pill bg-primary">{
							(userProfile.currentRole == "student" && "Estudiante")
								|| userProfile.roleMap[userProfile.currentRole].name
						}</div>
						{
							userProfile.student == null || userProfile.currentRole == "student" ? "" : <button type="button"
							  className="btn btn-outline-dark" onClick={() => changeRole("student")} disabled={idleLocked}
							  style={{display : "table-row", width : "100%"}}
							>
								<span style={{display : "table-cell", width : "100%"}}>
									Estudiante
								</span>
								<span style={{display : "table-cell"}}>
									<FontAwesomeIcon icon={solid("chevron-right")} />
								</span>
							</button>
						}
						{
							Object.keys(userProfile.roleMap).filter((key : string) => key != userProfile.currentRole).map
							(
								(key : string, index : number) => <button className="btn btn-outline-dark" disabled={idleLocked}
								  key={"r_" + index} onClick={() => changeRole(key)} style={{display : "table-row", width : "100%"}}
								>
									<span style={{display : "table-cell", width : "100%"}}>
										{userProfile.roleMap[key].name}
									</span>
									<span style={{display : "table-cell"}}>
										<FontAwesomeIcon icon={solid("chevron-right")} />
									</span>
								</button>
							)
						}
					</>
					</div></div>
			</Modal>
		}
		{
			(commonNotice.heading == null || commonNotice.message == null) ? "" : <Modal className={themes[ +themeToggle ]}
			  style={{overlay : {zIndex : 100}, content : {height : "min-content", background : "var(--second-bg)", borderRadius : "15px", boxShadow : "#959DA533 0px 8px 24px"}}}
			  appElement={document.getElementById('root') as HTMLElement} isOpen
			>
				<div style={{padding : "12px 15px 7px 15px"}}><div style={{display : "table-row"}}>
					<div style={{display : "table-cell", color : "var(--txt-color)", paddingTop : "5px", width : "100%"}}>
						<>{
							commonNotice.heading
						}</>
					</div>
					{
						commonNotice.cornerClose != true ? "" : <div style={{display : "table-cell"}}><button type="button"
						  className="button btn btn-default" onClick={() => dispatch(dismissNotice())} style=
						  {{
							color        : "var(--txt-color)",
							background   : "var(--main-bg)",
							borderRadius : "50%",
							width        : "35px", 
							border       : "1px solid #CCCCCC",
							padding      : "2px 0px 0px 2px"
						}}
						>
							<FontAwesomeIcon icon={solid("times")} size="2x" />
						</button></div>
					}
				</div></div>
				<div style={{padding : "30px", color : "var(--txt-color)"}}>
					{commonNotice.message}
					<br />
					<br />
					<div style={{textAlign : "right"}}>
						{
							!commonNotice.cornerClose && commonNotice.procedure ? "" : <button className="btn btn-dark"
							  type="button" onClick=
							  {
								()=>
								{
									dispatch(dismissNotice())

									if(commonNotice.optOutProcedure)
									{
										commonNotice.optOutProcedure()
									}
								}
							  }
							>
								{!commonNotice.cornerClose ? "Cerrar" : "Rechazar"}
							</button>
						}
						{
							commonNotice.procedure == null ? "" : <button className="btn btn-light" type="button" onClick=
							  {
								()=>
								{
									commonNotice.procedure()
									dispatch(dismissNotice())
								}
							  }
							>
								Aceptar
							</button>
						}
					</div>
				</div>
			</Modal>
		}
	</>
}

export default App