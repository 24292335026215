import React,
{
	useState,
	useRef,
	useReducer,
	useEffect,
	FormEvent
}
	from "react"
import {FontAwesomeIcon}
	from "@fortawesome/react-fontawesome"
import {solid}
	from "@fortawesome/fontawesome-svg-core/import.macro"
import Multiselect
	from "multiselect-react-dropdown"
import {useDispatch, useSelector}
	from "react-redux"
import {Popover}
	from "react-tiny-popover"
import _
	from "lodash"
import {sprintf}
	from "sprintf-js"
import
{
	RootState,

	enableIdleLockSwitch,
	disableIdleLockSwitch,

	displayNotice,
	dismissNotice
}
	from "./../../globals"
import CustomIcon
	from "./../../components/customIcon"
import SectionContainer,
{
	DataDisplayModal,
	UserPrompt
}
	from "./../../components/sectionContainer"
import UnderlineHeading
	from "./../../components/underlineHeading"
import
{
	buttonClickHandling,
	currencySuffix,
	enrollingSet,
	getAccessPermissions,
	imageTypes,
	isEmpty,
	pageGrouping,
	UIState,
	validateField,
	weekDays,
}
	from "./../../common"
import
{
	articleStatus,
	assignmentStatus,
	employeeStatus,
	courseStatus,
	courseLevelStatus,
	scheduleStatus,
	tunning,
}
	from "./../../constants"
import HttpManager
	from "./../../services/HttpManager"
import SimpleDataService
	from "./../../services/simpleData.service"
import CatalogService
	from "./../../services/catalog.service"
import CoursesService
	from "./../../services/campus/courses.service"
import CourseLevelsService
	from "./../../services/campus/courseLevels.service"
import PeriodsService
	from "./../../services/campus/periods.service"
import StudentsService
	from "./../../services/campus/students.service"
import AssignmentsService
	from "./../../services/assignments.service"
import SchedulesService
	from "./../../services/campus/schedules.service"
import EmployeesService
	from "./../../services/campus/employees.service"
import BuildingsService
	from "./../../services/campus/buildings.service"
import InventoryService
	from "./../../services/campus/inventory.service"
import StoreService
	from "./../../services/campus/store.service"
import defaultCourseImage
	from "./../../assets/images/course.png"
import LevelPrompt
	from "./levelPrompt"
import SchedulePrompt
	from "./schedulePrompt"
import EnrollmentPrompt
	from "./enrollments/enrollmentPrompt"

import "./../../components/dropdown/style.css"
import "./style.css"

type State                 = {isButtonDisabled : boolean}
const initialState : State = {isButtonDisabled : true}

type Action=
{
	type    : "setIsButtonDisabled",
	payload : boolean
}

const reducer = (state : State, action : Action) : State=>
{
	switch(action.type)
	{
		case "setIsButtonDisabled": 
			return{...state, isButtonDisabled : action.payload}
		break;
	}
}

const Courses = ()=>
{
	const CATEGORIES        = 0
	const COURSE_MODES      = 1
	const COURSE_STATUSES   = 2
	const LEVEL_STATUSES    = 3
	const MENTOR_COUNT      = 4
	const KIT_COUNT         = 5
	const BUILDINGS         = 6
	const PERIODS           = 7
	const ENDING            = 8

	let   fileReader : any
	const [categories, setCategories]                             = useState<any[]>([])
	const [courseStatuses, setCourseStatuses]                     = useState<any>(null)
	const [courseModes, setCourseModes]                           = useState<any>(null)
	const [levelStatuses, setLevelStatuses]                       = useState<any[]>([])
	const [scheduleStatuses, setScheduleStatuses]                 = useState<any[]>([])
	const [periods, setPeriods]                                   = useState<any[]>([])
	const [mentorCount, setMentorCount]                           = useState<number>(0)
	const [kitCount, setKitCount]                                 = useState<number>(0)
	const [buildings, setBuildings]                               = useState<any[]>([])
	const [classroomCount, setClassroomCount]                     = useState<number>(0)
	const [state, trigger]                                        = useReducer(reducer, initialState)
	const dispatch                                                = useDispatch()
	const [initSetup, setInitSetup]                               = useState<number | null>(null)
	const [studentsCount, setStudentsCount]                       = useState<number>(0)
	const [totalRecords, setTotalRecords]                         = useState<number>(0)
	const [data, setData]                                         = useState<any[]>([])
	const idleLocked   : boolean                                  = useSelector((state : RootState) => state.idleLockSwitch.value)
	const sessionToken : string | null                            = useSelector((state : RootState) => state.sessionToken.value)
	const basicInfo    : any                                      = useSelector((state : RootState) => state.basicInfo.value)
	const currentUserCampus : any                                 = useSelector((state : RootState) => state.currentUserCampus.value);
	const campusRef         : any                                 = useSelector((state : RootState) => state.campusRef.value)
	const currentUser       : any                                 = useSelector((state : RootState) => state.userProfile.value)
	const [userCampus, setUserCampus]                             = useState<string | null>(currentUserCampus)
	const noCampusesProvided : boolean                            = currentUserCampus == null
	const [noTagsProvided, setNoTagsProvided]                     = useState<boolean>(false)
	const [extendedFilterShown, setExtendedFilterShown]           = useState<boolean>(false)
	const [unfilledFields, setUnfilledFields]                     = useState<boolean>(false)
	const [unchangedRecord, setUnchangedRecord]                   = useState<boolean>(false)
	const [sectionLoaded, setSectionLoaded]                       = useState<boolean>(false)
	const [clearSearchRequested, setClearSearchRequested]         = useState<boolean>(false)
	const [atLeastOneUnlinkedCourse, setAtLeastOneUnlinkedCourse] = useState<boolean>(false)
	const [UIStatus, setUIStatus]                                 = useState<number>(UIState.NORMAL)
	const [selectedRecords, setSelectedRecords]                   = useState<number>(0)
	const [initMentors, setInitMentors]                           = useState<any>([])
	const [initKits, setInitKits]                                 = useState<any>([])
	const [initAssignments, setInitAssignments]                   = useState<any>([])
	const [buildingRef, setBuildingRef]                           = useState<any>({})
	const [scheduleStatusRef, setScheduleStatusRef]               = useState<any>({})
	const [categoryRef, setCategoryRef]                           = useState<any>({})
	const [courseStatusRef, setCourseStatusRef]                   = useState<any>({})
	const [courseModeRef, setCourseModeRef]                       = useState<any>({})
	const [warningReport, listWarnings]                           = useState<any>({})
	const [newCourse, setNewCourse]                               = useState<any>(null)
	const [firstUsableBuildingIndex, setFirstUsableBuildingIndex] = useState<any>(null)//
	const [selectedCourse, setSelectedCourse]                     = useState<any>(null)
	const [currentDate, setCurrentDate]                           = useState<any>(null)
	const [selectedUser, setSelectedUser]                         = useState<any>(null)
	const [editableCourse, setEditableCourse]                     = useState<any>(null)
	const [selectedLevel, setSelectedLevel]                       = useState<any>(null)
	const [editableLevel, setEditableLevel]                       = useState<any>(null)
	const [selectedSchedule, setSelectedSchedule]                 = useState<any>(null)
	const [selectedEnrollment, setSelectedEnrollment]             = useState<any>(null)
	const [defaultNewLevel, setDefaultNewLevel]                   = useState<any>(null)
	const [defaultNewSchedule, setDefaultNewSchedule]             = useState<any>(null)
	const [pagination, setPagination]                             = useState<any>(null)
	const [saveSuccess, setSaveSuccess]                           = useState<boolean>(false)
	const [saveError, setSaveError]                               = useState<any>(null)
	const [recordsSelection, setRecordsSelection]                 = useState<any>([])
	const count        : number[]                                 = [-1, 1]
	const allowed      : any                                      = getAccessPermissions(currentUser)
	const levelAllowance                                          = getAccessPermissions(currentUser, "levels")
	const scheduleAllowance                                       = getAccessPermissions(currentUser, "schedules")
	const enrollmentAllowance                                     = getAccessPermissions(currentUser, "enrollments")
	let   recordFormRef                                           = useRef<HTMLFormElement >(null)
	let   nameRef                                                 = useRef<HTMLInputElement>(null)
	let   codeRef                                                 = useRef<HTMLInputElement>(null)
	let   imageRef                                                = useRef<HTMLInputElement>(null)
	let   descriptionRef                                          = useRef<HTMLTextAreaElement>(null)
	const [suffix, setSuffix]                                     = useState<string | null>
		(currentUserCampus == null ? null : currencySuffix[campusRef[currentUserCampus].company.currency_id - 1])
	const [defaultSearch, setDefaultSearch]                       = useState<any>
	({
		q          : "",
		statusId   : courseStatus.ACTIVE,
		modeId     : -1,
		categoryId :  0,
		tags       : []
	})

	const [customSearch, setCustomSearch] = useState<any>(defaultSearch)

	const unedit = ()=>
	{
		if(editableCourse && editableCourse.id == null)
		{
			setSelectedCourse(null)
		}

		setEditableCourse(null)
	}

	const hideDetailPrompt = ()=>
	{
		if(editableLevel && editableLevel.id == null)
		{
			setSelectedLevel(null)
		}

		setEditableLevel(null)
	}

	const showPrompt = (recordData : any)=>
	{
		let course = {...recordData, imagePreview : recordData.image}
		setUIStatus(UIState.NORMAL)
		setSaveError(null)

		if(recordData.id == null)
		{
			setEditableCourse(course)
		}

		setSelectedCourse(course)
	}

	const procedureComplaint = (message : string)=>
	{
		dispatch
		(
			displayNotice
			({
				cornerClose : false,
				message     : message,
				heading     : <h3 style={{color : "#FF0000", display : "inline-block"}}>
					Error
				</h3>
			})
		)
	}

	const showDetailPrompt = async(recordData : any)=>
	{
		setUIStatus(UIState.NORMAL)

		if(recordData.id == null)
		{
			const recordCollationFailure = "Información relativa al módulo no pudo ser extraída"

			dispatch(enableIdleLockSwitch())

			try
			{
				let result : any = await AssignmentsService.searchAssignments
					({q : "", tagIds : _.map(recordData.course.tags, "id"), statusId : assignmentStatus.ACTIVE}, undefined, 100)

				if(result.status == 200 || result.status == 204)
				{
					setInitAssignments
					(
						result.data.map((assignment : any, index : number) => ({...assignment, refIndex : "K_" + assignment.id}))
					)

					if(noCampusesProvided)
					{
						setSelectedLevel(recordData)
					}
					else
					{
						let result0 : any = await EmployeesService.searchEmployees
						(
							{
								q           : "",
								onlyMentors : true,
								statusId    : employeeStatus.ACTIVE
							},
							undefined,
							100
						)

						if(result0.status == 200 || result0.status == 204)
						{
							setInitMentors
							(
								result0.data.map
								(
									(mentor : any, index : number)=>
									({
										...mentor, label : mentor.first_name + " " + mentor.last_name,
										refIndex         : "M_" + mentor.id,
									})
								)
								
							)

							let result1: any = await StoreService.searchArticles
							(
								campusRef[currentUserCampus].id,
								{
									q           : "",
									tagIds      : _.map(recordData.course.tags, "id"),
									categoryIds : [recordData.course.category_id],
									statusId    : articleStatus.AVAILABLE,
									onlyKits    : true
								},
								undefined,
								100
							)

							if(result1.status == 200 || result1.status == 204)
							{
								setInitKits(result1.data.map((kit : any, index : number) => ({...kit, refIndex : "K_" + kit.id })))

								setSelectedLevel(recordData)
							}
							else
							{
								procedureComplaint(recordCollationFailure)
							}
						}
						else
						{
							procedureComplaint(recordCollationFailure)
						}
					}
				}
				else
				{
					procedureComplaint(recordCollationFailure)
				}
			}
			catch(error)
			{
				console.log(error)
				procedureComplaint(recordCollationFailure)
			}
			finally
			{
				dispatch(disableIdleLockSwitch())
			}
		}
		else
		{
			setInitMentors([])
			setInitKits([])
			setInitAssignments([])
			setSelectedLevel(recordData)
		}
	}

	const showSubDetailPrompt = async (recordData : any)=>
	{
		if(recordData.id && recordData.status_id == scheduleStatus.ACTIVE)
		{
			const recordCollationFailure = "Información relativa al horario no pudo ser extraída"
			dispatch(enableIdleLockSwitch())
			try
			{
				let result : any = await SchedulesService.searchSchedules
				({
					startValue   : recordData.startValue,
					endValue     : recordData.endValue,
					classroomIds : [recordData.classroom.id],
					exceptionId  : recordData.id,
					statusId : scheduleStatus.ACTIVE
				})
				if(result.status == 200 || result.status == 204)
				{
					setSelectedSchedule({...recordData, matchingHoursData : result.data})
				}
				else
				{
					procedureComplaint(recordCollationFailure)
				}
			}
			catch(error)
			{
				console.log(error)
				procedureComplaint(recordCollationFailure)
			}
			finally
			{
				dispatch(disableIdleLockSwitch())
			}
		}
		else
		{
			setSelectedSchedule({...recordData, matchingHoursData : []})
		}
	}

	const saveRecord = ()=>
	{
		if(recordFormRef && recordFormRef.current)
		{
			if(!recordFormRef.current.checkValidity())
			{
				if(!unfilledFields)
				{
					setUnfilledFields(true)
					recordFormRef.current.reportValidity()

					setTimeout
					(
						() => setUnfilledFields(false),
						3000
					)
				}
			}
			else
			{
				if(editableCourse && editableCourse.tags.length < 1)
				{
					if(!unfilledFields && !noTagsProvided)
					{
						setUnfilledFields(true)
						setNoTagsProvided(true)

						setTimeout
						(
							() =>
							{
								setUnfilledFields(false)
								setNoTagsProvided(false)
							},
							3000
						)
					}
				}
				else
				{
					if(saveError != null)
					{
						setSaveError(null)
					}

					setUIStatus(UIState.LOCKED)
					dispatch(enableIdleLockSwitch())

					CoursesService.saveCourse(campusRef[currentUserCampus], editableCourse).then
					(
						()=>
						{
							setSaveSuccess(true)
							setUIStatus(UIState.SUCCESS)

							setTimeout
							(
								()=>
								{
									setSelectedCourse(null)
									setEditableCourse(null)
									setSaveSuccess(false)
									setUIStatus(UIState.NORMAL)
									search()
								},
								tunning.MODAL_DISMISS_DELAY
							)
						},
						(error : any)=>
						{
							console.log(error)
							setUIStatus(UIState.ERROR)
							dispatch(disableIdleLockSwitch())
							setSaveError
							(
								error.response.status != 409
									? "La información no pudo ser guardada"
									: "Hay conflictos en la información proporcionada (nombre). " +
									"Revise que los valores sean únicos en comparación con los registros existentes"
							)
						}
					)
				}
			}
		}
	}

	const handleSearchChange = (e : any)=>
	{
		if(e && e.target)
		{
			setCustomSearch({...customSearch, [e.target.name] : e.target.value})
		}
	}

	const imageLoad = (event : any)=>
	{
		if(event && event.target && event.target.files && event.target.files.length > 0)
		{
			if(event.target.files[0].size > 5242880 || imageTypes.indexOf(event.target.files[0].type) < 0)
			{
				setSaveError
				(
					<>
						Sólo se permiten imágenes con las siguientes características:
						<br />
						&emsp;Tamaño &lt;= 5MB, &nbsp;Formato: [BMP, GIF, JPEG, PNG, WEBP]
					</>
				)

				setUIStatus(UIState.ERROR)

				if(imageRef != null && imageRef.current != null)
				{
					imageRef.current.value = ""
				}
			}
			else
			{
				let course : any     = {...editableCourse, image : event.target.files[0]}
				fileReader           = new FileReader()
				fileReader.onloadend = (event : any)=>
				{
					setEditableCourse({...course, imagePreview : fileReader.result})

					if(imageRef != null && imageRef.current != null)
					{
						imageRef.current.value = ""
					}
				}

				fileReader.readAsDataURL(event.target.files[0])
			}
		}
	}

	const imageDisposal = ()=>
	{
		if(imageRef != null && imageRef.current != null)
		{
			imageRef.current.value = ""
		}

		handleRegistryChange
		({
			target:
			{
				name  : "image",
				value : null
			}
		})
	}

	const handleRegistryChange = (e : any)=>
	{
		if(e && e.target)
		{
			setEditableCourse({...editableCourse, [e.target.name] : e.target.value})
		}
	}

	const editCourse = ()=>
	{
		setEditableCourse
		({
			...selectedCourse, mode_id : selectedCourse.mode_id,
			description                : selectedCourse.description || ""
		})
	}
	//FX---------------------------------------------------------------------------------------------------------------------
	useEffect
	(
		()=>
		{
			const onLoad = async()=>
			{
				if(basicInfo.tags.length < 1)
				{
					procedureComplaint
					(
						"No existen etiquetas para ser asignadas a los cursos creados en el registro, " +
							"por lo que el acceso a este módulo permanecerá deshabilitado."
					)
				}
				else
				{
					if(currentUserCampus == null)
					{
						dispatch(disableIdleLockSwitch())
						procedureComplaint("No hay planteles registrados o asignados al rol en uso por el usuario, " +
							"por lo que el acceso a este módulo permanecerá deshabilitado.")
					}
					else
					{
						const catalogRetrievalError : string = "El catálogo de categorías de cursos no pudo ser extraído"

						dispatch(enableIdleLockSwitch())

						try
						{
							const result    = await SimpleDataService.getRecords("categories")

							if(result.status == 200 || result.status == 204)
							{
								let categoryMap : any= {}

								setCategories
								(
									result.data.map
									(
										(category : any)=>
										{
											categoryMap["C_" + category.id] = category

											return category
										}
									)
								)

								setCategoryRef(categoryMap)
								setInitSetup(CATEGORIES)
							}
							else
							{
								procedureComplaint(catalogRetrievalError)
							}
						}
						catch(error)
						{
							procedureComplaint(catalogRetrievalError)
						}
					}
				}
			}

			if(sessionToken)
			{
				onLoad()
			}
		},
		[]
	)

	useEffect
	(
		()=>
		{
			const onLoad = async()=>
			{
				switch(initSetup)
				{
					case CATEGORIES:
						if(categories.length < 1)
						{
							dispatch(disableIdleLockSwitch())
							procedureComplaint
							(
								"No hay categorías para cursos en el registro, " +
									"por lo que el acceso a este módulo permanecerá deshabilitado"
							)
						}
						else
						{
							const statusRetrievalError : string = "los estatuses para cursos no pudieron ser extraídos"

							try
							{
								const result = await CatalogService.getCourseStatuses(sessionToken)

								if(result.status == 200 || result.status == 204)
								{
									let courseStatusMap : any = {}

									result.data.map((status : any) => courseStatusMap["CS_" + status.id] = status)
									setCourseStatusRef(courseStatusMap)
									setCourseStatuses(Object.values(courseStatusMap))
									setInitSetup(COURSE_STATUSES)
								}
								else
								{
									procedureComplaint(statusRetrievalError)
								}
							}
							catch(error)
							{
								console.log(error)
								procedureComplaint(statusRetrievalError)
								dispatch(disableIdleLockSwitch())
							}
						}
					break;
					case COURSE_STATUSES:
						if(courseStatuses.length < 1)
						{
							dispatch(disableIdleLockSwitch())
							procedureComplaint
							(
								"No hay estatuses de curso en el registro, " +
									"por lo que el acceso a este módulo permanecerá deshabilitado"
							)
						}
						else
						{
							const modeRetrievalError : string = "las modalidades para cursos no pudieron ser extraídos"

							try
							{
								const result      : any = await CatalogService.getCourseModes(sessionToken)
								let courseModeMap : any = {}

								if(result.status == 200 || result.status == 204)
								{
									setCourseModes
									(
										result.data.map
										(
											(mode : any) =>
											{
												courseModeMap["CM_" + mode.id] = mode

												return mode
											}
										)
									)

									setCourseModeRef(courseModeMap)
									setInitSetup(COURSE_MODES)
								}
								else
								{
									procedureComplaint(modeRetrievalError)
								}
							}
							catch(error)
							{
								console.log(error)
								procedureComplaint(modeRetrievalError)
								dispatch(disableIdleLockSwitch())
							}
						}
					break;
					case COURSE_MODES:
						if(courseModes.length < 1)
						{
							dispatch(disableIdleLockSwitch())
							procedureComplaint
							(
								"No hay modalidades de curso en el registro, " +
								"por lo que el acceso a este módulo permanecerá deshabilitado"
							)
						}
						else
						{
							const levelStatusRetrievalError : string = "los estatuses para módulos no pudieron ser extraídos"

							try
							{
								const result = await CatalogService.getLevelStatuses(sessionToken)

								if(result.status == 200 || result.status == 204)
								{
									setLevelStatuses(result.data)
									setInitSetup(LEVEL_STATUSES)
								}
								else
								{
									procedureComplaint(levelStatusRetrievalError)
								}
							}
							catch(error)
							{
								console.log(error)
								procedureComplaint(levelStatusRetrievalError)
								dispatch(disableIdleLockSwitch())
							}
						}
					break;
					case LEVEL_STATUSES:
						if(levelStatuses.length < 1)
						{
							procedureComplaint("No hay estatuses de módulo en el registro, " +
								"por lo que el acceso a esta secci{on permanecerá deshabilitada")
						}
						else
						{
							const employeeRetrievalError : string = "La lista de profesores no pudo ser extraída"

							if(!noCampusesProvided)
							{
								dispatch(enableIdleLockSwitch())

								try
								{
									const result = await EmployeesService.countEmployees({onlyMentors : true, statusId : employeeStatus.ACTIVE})

									if(result.status == 200 || result.status == 204)
									{
										setMentorCount(result.data.count)

										setInitSetup(MENTOR_COUNT)
									}
									else
									{
										listWarnings({...warningReport, mentorCount : employeeRetrievalError})
									}
								}
								catch(error)
								{
									console.log(error)

									listWarnings({...warningReport, mentorCount : employeeRetrievalError})
									dispatch(disableIdleLockSwitch())
									setInitSetup(ENDING)
								}
							}
							else
							{
								setInitSetup(ENDING)
							}
						}
					break;
					case MENTOR_COUNT:
						if(mentorCount < 1)
						{
							listWarnings({...warningReport, professors : "No se encontraron profesores en el registro para agregar a horarios"})
						}

						const kitRetrievalError : string = "los artículos  no pudieron ser extraídos. " +
							"por lo que no se podrán asociar artículos a los módulos " +
									"ni registrar alumnos dentro de los horarios creados"

						try
						{
							const expand : string = "classrooms"
							const result : any    = await InventoryService.countArticles
								(campusRef[currentUserCampus].id, {typeIds : [1, 2]})

							if(result.status == 200 || result.status == 204)
							{
								setKitCount(result.data.count)
								setInitSetup(KIT_COUNT)
							}
							else
							{
								listWarnings({...warningReport, kits : kitRetrievalError})

								setInitSetup(BUILDINGS)
							}
						}
						catch(error)
						{
							console.log(error)
							listWarnings({...warningReport, kits : kitRetrievalError})

							setInitSetup(BUILDINGS)
						}
					break;
					case KIT_COUNT:
						if(kitCount < 1)
						{
							listWarnings
							({
								...warningReport, kits : "No hay artículos en el registro, " + 
									"por lo que no se podrán asociar artículos a los módulos " +
										"ni registrar alumnos dentro de los horarios creados"
							})
						}

						try
						{
							let   classroomIndex                   : any
							let   buildingIndex                    : any
							let   formattedBuilding                : any
							let   firstCampusWithClassroomsIndex   : number
							let   classroomCounting                : number =  0
							let   firstBuildingWithClassroomsIndex : number = -1
							const searchParams                     : any    = null
							const expand                           : string = "classrooms"
							const result                           : any    = await BuildingsService.searchBuildings
								(campusRef[currentUserCampus].id, searchParams, expand)

							if(result.status == 200 || result.status == 204)
							{
								if(result.data.length < 1)
								{
									listWarnings
									({
										...warningReport, campuses : "No hay edificios en el registro, " + 
											"por lo que no se podrán agregar horarios dentro de los cursos y módulos creados"
									})
								}
								else
								{
									buildingIndex                  = {}
									firstCampusWithClassroomsIndex = -1

									setBuildings
									(
										result.data.map
										(
											(building : any, index : number)=>
											{
												classroomCounting += building.classrooms.length
												classroomIndex     = {}

												formattedBuilding=
												{
													...building, classrooms : building.classrooms.map
													(
														(classroom : any, index0 : number)=>
														{
															classroomIndex["C_" + classroom.id] = {...classroom, label : classroom.name}

															if(firstCampusWithClassroomsIndex < 0)
															{
																firstCampusWithClassroomsIndex = index
															}

															if(firstBuildingWithClassroomsIndex < 0)
															{
																firstBuildingWithClassroomsIndex = index0
															}

															return classroomIndex["C_" + classroom.id]
														}
													)
												}

												buildingIndex["B_" + formattedBuilding.id] = {...formattedBuilding, classroomRef : classroomIndex}

												return buildingIndex["B_" + formattedBuilding.id]
											}
										)
									)

									if(classroomCounting < 1)
									{
										listWarnings
										({
											...warningReport, campuses : "No hay aulas en el registro, " +
												"por lo que no se podrán agregar horarios dentro de los cursos y módulos creados"
										})
									}

									setBuildingRef(buildingIndex)
								}

								setFirstUsableBuildingIndex(firstBuildingWithClassroomsIndex)
								setClassroomCount(classroomCounting)
								setInitSetup(BUILDINGS)
							}
							else
							{
								listWarnings({...warningReport, students : "los edificios  no pudieron ser extraídos."})

								setInitSetup(BUILDINGS)
							}
						}
						catch(error)
						{
							console.log(error)
							listWarnings
							({
								...warningReport, students : "los edificios  no pudieron ser extraídos. " +
									"No se podrán agregar horarios dentro de los cursos y módulos creados"
							})

							setInitSetup(BUILDINGS)
						}
					break;
					case BUILDINGS:
						try
						{
							const result = await PeriodsService.searchPeriods(campusRef[currentUserCampus].id)

							if(result.status == 200 || result.status == 204)
							{
								if(result.data.length > 0)
								{
									setPeriods(result.data.map((period : any) => ({...period, label : period.name})))
									setInitSetup(PERIODS)
								}
								else
								{
									listWarnings
									({
										...warningReport, students : "No hay periodos registrados o vinculados a este plantel, " +
											"por lo que no se podrá acceder a horarios y matrículas"
									})

									setInitSetup(ENDING)
								}
							}
							else
							{
								listWarnings({...warningReport, students : "Los periodos no pudieron ser extraídos. No se podrá acceder a horarios y matrículas"})

								setInitSetup(ENDING)
							}
						}
						catch(error)
						{
							console.log(error)
							listWarnings({...warningReport, students : "Los periodos no pudieron ser extraídos. No se podrá acceder a horarios y matrículas"})

							setInitSetup(ENDING)
						}
					break;
					case PERIODS:
						try
						{
							const result = await StudentsService.getStudentsCount(campusRef[currentUserCampus].id)

							if(result.status == 200 || result.status == 204)
							{
								setStudentsCount(result.data.count)

								if(result.data.count < 1)
								{
									listWarnings({...warningReport, students : "No hay alumnos registrados, por lo que no se podrá acceder a las matrículas"})
								}

								setInitSetup(ENDING)
							}
							else
							{
								listWarnings({...warningReport, students : "El conteo de estudiantes no pudo ser extraído. No se podrá acceder a las matrículas"})

								setInitSetup(ENDING)
							}
						}
						catch(error)
						{
							console.log(error)
							listWarnings({...warningReport, students : "El conteo de estudiantes no pudo ser extraído. No se podrá acceder a las matrículas"})

							setInitSetup(ENDING)
						}
					break;
					case ENDING:
						setNewCourse
						({
							name        : "",
							code        : "",
							description : "",
							tags        : [],
							mode_id     : courseModes[0].id,
							category_id : categories[0].id,
							status_id   : courseStatus.ACTIVE
						})

						setDefaultNewLevel
						({
							name                                    : "",
							code                                    : "",
							startValue                              : "",
							endValue                                : "",
							description                             : "",
							status_id                               : courseLevelStatus.ACTIVE,
							mentors                                 : [],
							kits                                    : [],
							assignments                             : [],
							session_min_hours                       : 1,
							week_hours                              : 1,
							week_min_days                           : 1,
							subscription_base_price_mxn             : 1000,
							subscription_base_price_hnl             : 1000,
							subscription_base_price_usd             : 1000,
							monthly_billing_base_price_mxn          : 1000,
							monthly_billing_base_price_hnl          : 1000,
							monthly_billing_base_price_usd          : 1000,
							subscription_surcharge_base_cost_mxn    : 1000,
							subscription_surcharge_base_cost_hnl    : 1000,
							subscription_surcharge_base_cost_usd    : 1000,
							monthly_billing_surcharge_base_cost_mxn : 1000,
							monthly_billing_surcharge_base_cost_hnl : 1000,
							monthly_billing_surcharge_base_cost_usd : 1000,
							second_surcharge_base_cost_mxn          : 1000,
							second_surcharge_base_cost_hnl          : 1000,
							second_surcharge_base_cost_usd          : 1000
						})

						if(!noCampusesProvided && ((periods.length > 0 && classroomCount > 0) && firstUsableBuildingIndex >= 0))
						{
							setDefaultNewSchedule
							({
								name                           : "",
								code                           : "",
								notes                          : "",
								status_update_motive           : "",
								billable_months                : 1,
								students_minimum               : 15,
								students_limit                 : 50,
								hours                          : {},
								subscription_price             : 1000,
								monthly_billing_price          : 1000,
								subscription_surcharge_cost    : 1000,
								monthly_billing_surcharge_cost : 1000,
								second_surcharge_cost          : 1000,
								mentor_fee                     : 0,
								status_id                      : scheduleStatus.ACTIVE,
								campus_id                      : campusRef[currentUserCampus].id,
								building_id                    : buildings[firstUsableBuildingIndex].id,
								first_surcharge_month_day      : 20,
								second_surcharge_month_day     : 21
							})
						}

						setSectionLoaded(true)

						search()
					break;
				}
			}

			onLoad()
		},
		[initSetup]
	)

	useEffect(() => {setTotalRecords(data.length)}, [data])

	useEffect
	(
		()=>
		{
			if (clearSearchRequested && _.isEqual(defaultSearch, customSearch))
			{
				search()
			}
		},
		[customSearch, clearSearchRequested]
	)

	useEffect
	(
		()=>
		{
			trigger
			({
				type    : "setIsButtonDisabled",
				payload : (idleLocked || UIStatus == UIState.SUCCESS)
			})
		},
		[UIStatus, idleLocked]
	)

	useEffect
	(
		()=>
		{
			if((sectionLoaded && currentUserCampus) && (currentUserCampus != userCampus))
			{
				dispatch(enableIdleLockSwitch())
				setCustomSearch(defaultSearch)
				setSectionLoaded(false)
				setKitCount(0)
				setMentorCount(0)
				setFirstUsableBuildingIndex(null)
				setClassroomCount(0)
				setStudentsCount(0)
				setPeriods([])
				setBuildings([])
				listWarnings({})
				setSuffix(currencySuffix[campusRef[currentUserCampus].company.currency_id - 1])
				localStorage.setItem("currentCampus", currentUserCampus)
				setUserCampus(currentUserCampus)
				setInitSetup(LEVEL_STATUSES)
			}
		},
		[currentUserCampus, sectionLoaded]
	)
	//-----------------------------------------------------------------------------------------------------------------------
	const clearSearch = ()=>
	{
		setCustomSearch(defaultSearch)
		setClearSearchRequested(true)
	}
	
	const linkCoursesToCurrentCampus = ()=>
	{
		const recordIds : number[] = data
			.filter((item : any, index : number) => item.selected && item.data.campus_id == null)
			.map((item : any, index : number) => item.data.id)

		dispatch
		(
			displayNotice
			({
				cornerClose : true,
				message     : recordIds.length < 1
					? "Los cursos seleccionados ya se encuentran vinculados al plantel actual"
						: "¿Vincular cursos seleccionados al plantel actual?",
				heading     : <h3 style={{color : "#0000FF", display : "inline-block"}}>
					{recordIds.length < 1 ? "Aviso" : "Confirme"}
				</h3>,
				procedure   : recordIds.length < 1 ? null : async ()=>
				{
					const removalError : string   = "El comando no pudo ser procesado"

					dispatch(enableIdleLockSwitch())
					dispatch(dismissNotice())

					try
					{
						const result = await CoursesService.bindCoursesToCampus(campusRef[currentUserCampus].id, recordIds)

						if(result.status == 200 || result.status == 204)
						{
							setCustomSearch(defaultSearch)
							setData(data.map((record : any) => {return {selected : false, data : record.data}}))
							setSelectedRecords(0)
							search()
						}
						else
						{
							console.log(result.status)
							procedureComplaint(removalError)
						}
					}
					catch(error)
					{
						console.log(error)
						procedureComplaint(removalError)
					}
					finally
					{
						dispatch(disableIdleLockSwitch())
					}
				}
			})
		)
	}

	const search = async(page? : any)=>
	{
		const searchError : string = "La búsqueda no pudo completarse"

		try
		{
			let expand           : number  = 2
			let levelsWithNoKits : boolean = false

			dispatch(enableIdleLockSwitch())

			if(extendedFilterShown)
			{
				setExtendedFilterShown(false)
			}

			const result = await CoursesService.searchCourses
				(campusRef[currentUserCampus], customSearch, expand, isNaN(page) ? 1 : (page || 1))

			if(result.status == 200 || result.status == 204)
			{
				setCurrentDate(new Date(result.data.date + " 23:00:00"))
				setPagination(pageGrouping(result.data.current_page, result.data.last_page))
				let mentorRef            : any
				let mentors              : any
				let kitRef               : any
				let kits                 : any
				let assignmentRef        : any
				let assignments          : any
				let hours                : any
				let refIndex             : string
				let startMinutes         : number
				let startHours           : number
				let endMinutes           : number
				let endHours             : number
				let currentHours         : number
				let weekSessions         : any
				let aCourseWithoutCampus : boolean = false
				setData
				(
					result.data.data.map
					(
						(record : any, index : number)=>
						{
							if(record.campus_id == null && !aCourseWithoutCampus)
							{
								aCourseWithoutCampus = true
							}

							return{
								selected : false,
								data:
								{
									...record, levels : isEmpty(record.levels) ? [] : record.levels.map
									(
										(level : any)=>
										{
											if(!levelsWithNoKits && level.kits.length < 1)
											{
												levelsWithNoKits = true;
											}
											//mentorRef is used to store each mentor session half hour-----------------------
											mentorRef = {}
											mentors   = level.mentors.map
											(
												(mentor : any)=>
												{
													refIndex            = "M_" + mentor.id
													mentorRef[refIndex] = {}

													if(mentor.sessions)
													{
														mentor.sessions.map
														(
															(session : any)=>
															{
																let currentHours : any = session.start_hours

																while(currentHours < session.end_hours)
																{
																	mentorRef[refIndex][sprintf("DH%d%03d", session.week_day, currentHours)]=
																		session.schedule_id

																	currentHours += 5
																}
															}
														)
													}

													return {...mentor, label : mentor.first_name + " " + mentor.last_name}
												}
											)

											kitRef = {}
											kits = level.kits.map
											(
												(kit : any)=>
												{
													refIndex         = "K_" + kit.id
													kitRef[refIndex] = {...kit, refIndex : refIndex}

													return kitRef[refIndex]
												}
											)

											return{
												...level, kits : kits,
												kitRef         : kitRef,
												mentorRef      : mentorRef,
												mentors        : level.mentors.map((mentor : any) => ({...mentor, label : mentor.first_name + " " + mentor.last_name})),
												assignments    : level.assignments.map
												(
													(assignment : any)=>
													{
														return{
															...assignment, availablePoints : assignment.pivot.available_points,
															availability_start             : assignment.pivot.availability_start,
															availability_end               : assignment.pivot.availability_end,
															availabilityStartValue         : new Date
																(assignment.pivot.availability_start + " 23:00:00"),
															availabilityEndValue           : new Date
																(assignment.pivot.availability_end   + " 23:00:00")
														}
													}
												),
												schedules : level.schedules.length < 1 ? [] : level.schedules.map
												(
													(schedule : any)=>
													{
														hours        = {}
														weekSessions = []

														schedule.sessions.map
														(
															(session : any)=>
															{
																currentHours = session.start_hours
																startMinutes = (session.start_hours % 10)
																startHours   = (session.start_hours - startMinutes) / 10
																endMinutes   = (session.end_hours % 10)
																endHours     = (session.end_hours - endMinutes) / 10

																weekSessions.push
																(
																	weekDays[session.week_day].substr(0, 2) + sprintf
																	(
																		" %02d:%02d-%02d:%02d",
																		startHours, startMinutes * 6, endHours  , endMinutes * 6
																	)
																)

																while(currentHours < session.end_hours)
																{
																	hours[sprintf("DH%d%03d", session.week_day, currentHours)] = true
																	currentHours                                              += 5
																}
															}
														)

														return{
															...schedule, hours : hours,
															weekSessions       : weekSessions,
															campus_id          : schedule.classroom.building.campus_id,
															period             : {...schedule.period, label : schedule.period.name},
															classroom          : {...schedule.classroom, label : schedule.classroom.name},
															mentor             : (schedule.mentor == null || schedule.mentor.id == null)
																? null 
																	: {...schedule.mentor, label : schedule.mentor.first_name + " " + schedule.mentor.last_name},
														}
													}
												)
											}
										}
									)
								}
							}
						}
					)
				)

				if(levelsWithNoKits)
				{
					listWarnings({...warningReport, courseKits : "Hay módulos sin kits asociados."})
				}
				else
				{
					if(warningReport.courseKits)
					{
						delete warningReport.courseKits
					}
				}

				setAtLeastOneUnlinkedCourse(aCourseWithoutCampus)
				setSelectedRecords(0)
			}
			else
			{
				procedureComplaint(searchError)
			}
		}
		catch(error)
		{
			console.log(error)

			procedureComplaint(searchError)
		}
		finally
		{
			dispatch(disableIdleLockSwitch())
			setClearSearchRequested(false)
		}
	}

	const toggleRecordSelection = (position : number)=>
	{
		setData
		(
			(current : any) => current.map
			(
				(record : any, index : number)=>
				{
					if(index == position)
					{
						let value = !record.selected

						setSelectedRecords(selectedRecords + count[ +value ])

						return {...record, selected : value};
					}

					return record;
				}
			)
		)
	}

	const toggleSelectAll = ()=>
	{
		const selected = selectedRecords < totalRecords

		setData
		(
			(current : any) => current.map
			(
				(record : any, index : number)=>
				{
					return {...record, selected : selected};
				}
			)
		);

		setSelectedRecords(totalRecords * (+selected))
	}

	const closePrompt = ()=>
	{
		setSelectedCourse(null)
		setEditableCourse(null)
	}

	const closeDetailPrompt = ()=>
	{
		setSelectedLevel(null)
		setEditableLevel(null)
	}

	const confirmRemoval = (recordId? : any)=>
	{
		dispatch
		(
			displayNotice
			({
				cornerClose : true,
				message     : "¿Eliminar registro" + (!isNaN(recordId) || recordsSelection.length < 2 ? "" : "s") + "?",
				heading     : <h3 style={{color : "#0000FF", display : "inline-block"}}>
					Confirme
				</h3>,
				procedure   : async ()=>
				{
					const removalError : string   = "El comando no pudo ser procesado"
					const recordIds    : number[] = !isNaN(recordId)
						? [recordId]
							: data.filter((item : any, index : number) => item.selected).map((item : any, index : number) => item.data.id)

					dispatch(enableIdleLockSwitch())
					dispatch(dismissNotice())

					try
					{
						const result = await CoursesService.removeCourses
							(campusRef[currentUserCampus].id, recordIds)

						if(result.status == 200 || result.status == 204)
						{
							setCustomSearch(defaultSearch)
							setData(data.map((record : any) => {return {selected : false, data : record.data}}))
							setSelectedRecords(0)
							search()
						}
						else
						{
							console.log(result.status)
							procedureComplaint(removalError)
						}
					}
					catch(error)
					{
						console.log(error)
						procedureComplaint(removalError)
					}
					finally
					{
						dispatch(disableIdleLockSwitch())
					}
				}
			})
		)
	}

	const confirmDetailRemoval = (courseId : number, levelId : number)=>
	{
		dispatch
		(
			displayNotice
			({
				message   : "¿Eliminar módulo?",
				heading   : <h3 style={{color : "#0000FF", display : "inline-block"}}>
					Confirme
				</h3>,
				procedure : async ()=>
				{
					const removalError : string   = "El comando no pudo ser procesado"

					dispatch(enableIdleLockSwitch())
					dispatch(dismissNotice())

					try
					{
						const result = await CourseLevelsService.removeLevels
							(campusRef[currentUserCampus].id, [levelId])

						if(result.status == 200 || result.status == 204)
						{
							setData(data.map((record : any, index : number) => {return {selected : false, data : record}}))
							setSelectedRecords(0)
							search()
						}
						else
						{
							console.log(result.status)
							procedureComplaint(removalError)
						}
					}
					catch(error)
					{
						console.log(error)
						procedureComplaint(removalError)
					}
					finally
					{
						dispatch(disableIdleLockSwitch())
					}
				}
			})
		)
	}

	const confirmSubDetailRemoval = (subDetail : any)=>
	{
		dispatch
		(
			displayNotice
			({
				message   : "¿Eliminar horario?",
				heading   : <h3 style={{color : "#0000FF", display : "inline-block"}}>
					Confirme
				</h3>,
				procedure : async ()=>
				{
					const scheduleRemovalError : string = "El comando no pudo ser procesado. " +
						"Asegúrese de que ninguna otra información dependa de este registro en específico"

					dispatch(enableIdleLockSwitch())
					dispatch(dismissNotice())

					try
					{
						const result = await SchedulesService.removeSchedules
							(campusRef[currentUserCampus].id, [subDetail.id])

						if(result.status == 200 || result.status == 204)
						{
							setData(data.map((record : any, index : number)=>{return {selected : false, data : record}}))
							setSelectedRecords(0)
							search()
						}
						else
						{
							console.log(result.status)
							procedureComplaint(scheduleRemovalError)
						}
					}
					catch(error)
					{
						console.log(error)
						procedureComplaint(scheduleRemovalError)
					}
					finally
					{
						dispatch(disableIdleLockSwitch())
					}
				}
			})
		)
	}

	const promptEnrolling = async(course : any, level : any, schedule : any)=>
	{
		let retrievalError : string = "La lista de matrículas no pudo ser extraída"

		try
		{
			dispatch(enableIdleLockSwitch())

			const result = await HttpManager.getInstance().enrollmentService.searchEnrollments(schedule.id)

			if(result.status == 200 || result.status == 204)
			{
				setSelectedEnrollment(enrollingSet(course, level, schedule, result.data, campusRef[currentUserCampus].company_id))
			}
			else
			{
				procedureComplaint(retrievalError)
			}
		}
		catch(error)
		{
			console.log(error)
			procedureComplaint(retrievalError)
		}
		finally
		{
			dispatch(disableIdleLockSwitch())
		}
	}

	return !sectionLoaded || pagination == null ? <></> : <>
		<SectionContainer
		  addingPromptFn={() => showPrompt(newCourse)}
		  searchFn={search}
		  searchChangeFn={handleSearchChange}
		  title="Cursos"
		  defaultSearch={defaultSearch}
		  clearSearchRequested={clearSearchRequested}
		  clearSearchFn={clearSearch}
		  pagination={pagination}
		  selectedRecords={selectedRecords}
		  removalFn={confirmRemoval}
		  customSearch={customSearch}
		  generalPlaceHolder={"Nombre, código, descripción"}
		  searchIcon={<FontAwesomeIcon icon={solid("calendar-check")} />}
		  allowed={allowed}
		  extendedFilterShown={extendedFilterShown}
		  setExtendedFilterShown={setExtendedFilterShown}
		  extendedFilterViewClosing={() => setExtendedFilterShown(false)}
		  extendedMultipleFunctionality=
		  {!atLeastOneUnlinkedCourse ? null : <button
			  type="button"
			  disabled={idleLocked || selectedRecords < 1}
			  style={{width : "100%", textAlign : "left"}}
			  className="btn btn-success"
			  onClick={linkCoursesToCurrentCampus}
		  >
			<FontAwesomeIcon icon={solid("link")} />
			&nbsp;
			<CustomIcon
			  name="campus"
			  style={{fill : "#FFFFFF"}}
			/>
			&nbsp;
			Vincular a plantel
		  </button>}
		  filterSummary=
		  {
			[
				{
					label   : "Estatus",
					display : <p style={{fontSize: "16px", margin: 0, textAlign: "center"}}>
						{customSearch.statusId < 0 ? "Mostrar todo" : courseStatusRef["CS_" + customSearch.statusId].name}
					</p>
				},
				customSearch.modeId < 0 ? null :
				{
					label   : "Modalidad",
					display : <p style={{fontSize: "16px", margin: 0, textAlign: "center"}}>
						{courseModeRef["CM_" + customSearch.modeId].name}
					</p>
				},
				customSearch.categoryId < 1 ? null :
				{
					label   : "Categoría",
					display : <p style={{fontSize: "16px", margin: 0, textAlign: "center"}}>
						{categoryRef["C_" + customSearch.categoryId].name}
					</p>
				},
				customSearch.tags.length < 1 ? null : 
				{
					label   : "Etiquetas",
					display : <div style={{display : "flex", flexFlow : "wrap"}}>{
						customSearch.tags.map
						(
							(tag : any) => <div key={"tf_" + tag.id} className="badge rounded-pill bg-primary">
								{tag.name}
							</div>
						)
					}</div>
				}
			].filter((setting : any) => setting)
		  }
		  advancedSearchForm=
		  {<div className="container"><div className="row">
			<div className="col-sm-6">
				<fieldset>
					<legend><label style={{position : "relative", top : "4px"}}>
						Estatus
					</label></legend>
					<button className="form-control btn btn-default" disabled={idleLocked || customSearch.statusId < 0}
					  type="button" onClick={() => setCustomSearch({...customSearch, statusId : -1})} style={{textAlign : "left"}}
					>
						<FontAwesomeIcon icon={customSearch.statusId < 0 ? solid("circle-dot") : solid("circle")} />
						<span style={{paddingLeft : "5px"}}>
							Mostrar todo
						</span>
					</button>
					{
						courseStatuses.map
						(
							(status : any) => <button onClick={() => setCustomSearch({...customSearch, statusId : status.id})}
							  disabled={idleLocked || customSearch.statusId == status.id} className="form-control btn btn-default"
							  type="button" key={"CS_" + status.id} style={{textAlign : "left"}}
							>
								<FontAwesomeIcon icon={customSearch.statusId == status.id ? solid("circle-dot") : solid("circle")} />
								<span style={{paddingLeft : "5px"}}>
									{status.name}
								</span>
							</button>
						)
					}
				</fieldset>
				<fieldset>
					<legend><label style={{position : "relative", top : "4px"}}>
						Modalidad
					</label></legend>
					<button className="form-control btn btn-default" disabled={idleLocked || customSearch.modeId < 0} type="button"
					  onClick={() => setCustomSearch({...customSearch, modeId : -1})} style={{textAlign : "left"}}
					>
						<FontAwesomeIcon icon={customSearch.modeId < 0 ? solid("circle-dot") : solid("circle")} />
						<span style={{paddingLeft : "5px"}}>
							Mostrar todo
						</span>
					</button>
					{
						courseModes.map
						(
							(mode : any) => <button onClick={() => setCustomSearch({...customSearch, modeId : mode.id})} type="button"
							  disabled={idleLocked || customSearch.modeId == mode.id} className="form-control btn btn-default"
							  key={"S_" + mode.id} style={{textAlign : "left"}}
							>
								<FontAwesomeIcon icon={customSearch.modeId == mode.id ? solid("circle-dot") : solid("circle")} />
								<span style={{paddingLeft : "5px"}}>
									{mode.name}
								</span>
							</button>
						)
					}
				</fieldset>
			</div>
			<div className="col-sm-6">
				<fieldset>
					<legend><label style={{position : "relative", top : "4px"}}>
						Categoría
					</label></legend>
					<div className="selectContainer" style={{marginBottom : "unset", height : "32px"}}>
						<select value={customSearch.categoryId} disabled={idleLocked} name="categoryId"
						  className="btn btn-primary text-start" onChange={handleSearchChange} id="categoryId"
						  style={{height : "30px", marginTop : "unset"}}
						>
							<option value={-1}>
								Todas
							</option>
							{
								categories.map
								(
									(record : any, i : any) => <option key={i} value={record.id}>
										{record.name}
									</option>
								)
							}
						</select>
						<div className="iconRight" style={{pointerEvents : "none"}}>
							<CustomIcon name="rowDown" />
						</div>
					</div>
				</fieldset>
				<fieldset>
					<legend><label style={{position : "relative", top : "4px"}}>
						Etiquetas
					</label></legend>
					<Multiselect onSelect={(tags : any) => setCustomSearch({...customSearch, tags : tags})} displayValue="name"
					  onRemove={(tags : any) => setCustomSearch({...customSearch, tags : tags})} placeholder="Seleccione"
					  emptyRecordMsg="No hay más opciones" options={basicInfo.tags} selectedValues={customSearch.tags}
					/>
				</fieldset>
			</div>
		  </div></div>}
		>
			<>
				{
					Object.keys(warningReport).map
					(
						(code : string) => <div style={{padding : "unset", position : "sticky", left : "0px"}} key={"warn_" + code}
						  className="callout callout-warning"
						>
							<table style={{width : "100%"}}><tbody><tr>
								<td style={{padding : "16px", verticalAlign : "middle"}}>
									<FontAwesomeIcon icon={solid("triangle-exclamation")} />
								</td>
								<td style={{width : "100%"}}>
									{warningReport[code]}
								</td>
								<td style={{verticalAlign : "top"}}><button className="btn btn-default" style={{padding : "unset"}}
								  type="button" onClick=
								  {
									()=>
									{
										let warn = warningReport

										delete warn[code]
										listWarnings({...warn})
									}
								  }
								>
									<FontAwesomeIcon icon={solid("times")} style={{margin : "20px 15px 0px"}}
									 
									/>
								</button></td>
							</tr></tbody></table>
						</div>
					)
				}
				{
					(data == null || data.length < 1)
						?
					<>
						<hr />
						<div style={{padding : "25px"}}>
							Sin resultados.
						</div>
						<hr />
					</>
						:
					<table className="specialTable">
						<thead>
							<tr key="coursesHeader">
								{
									!allowed.delete ? "" : <th><button className="btn btn-default" onClick={toggleSelectAll} type="button"
									  disabled={idleLocked || saveSuccess}
									>
										{<CustomIcon name={selectedRecords == totalRecords ? "bCheck" : "bUncheck"} />}
									</button></th>
								}
								<th>
									id
								</th>
								<th>
									<FontAwesomeIcon  icon={solid("image")} />
								</th>
								<th>
									Nombre
								</th>
								<th>
									Código
								</th>
								<th>
									Modalidad
								</th>
								<th>
									Estatus
								</th>
								<th>
									Etiquetas
								</th>
								<th>
									Descripción
								</th>
								<th />
							</tr>
						</thead>
						{
							data.map
							(
								(record : any, index : number)=>
								<tbody className="course" key={"campus" + index}>
									<tr>
										{
											!allowed.delete ? "" : <td style={{outline : "10px solid #F3F8FF", background : "#F3F8FF"}}>
												<button className="btn btn-default" onClick={() => toggleRecordSelection(index)}
												  type="button" disabled={idleLocked}
												>
												{<CustomIcon name={record.selected ? "bCheck" : "bUncheck"} />}
											</button></td>
										}
										<td style={{outline : "10px solid #F3F8FF", background : "#F3F8FF"}}>
											{record.data.id}
										</td>
										<td style={{outline : "10px solid #F3F8FF", width : "1px", background : "#F3F8FF"}}>
											<img src={record.data.image != null ? record.data.image : defaultCourseImage}
											  className="relatedPicture"
											/>
										</td>
										<td style={{outline : "10px solid #F3F8FF", background : "#F3F8FF"}}>
											{record.data.name}
										</td>
										<td style={{outline : "10px solid #F3F8FF", background : "#F3F8FF"}}>
											{record.data.code}
										</td>
										<td style={{outline : "10px solid #F3F8FF", background : "#F3F8FF"}}>
											{record.data.mode?.name}
										</td>
										<td style={{outline : "10px solid #F3F8FF", background : "#F3F8FF"}}>
											{record.data.status?.name}
										</td>
										<td style={{outline : "10px solid #F3F8FF", background : "#F3F8FF"}}>{
											record.data.tags && record.data.tags.map
											(
												(item : any, index : number) => <div className="badge rounded-pill bg-primary"
												  key={"crc_" + item.id}
												>
													{item.name}
												</div>
											)
										}</td>
										<td style={{outline : "10px solid #F3F8FF", background : "#F3F8FF"}}>
											{record.data.description || "-"}
										</td>
										<td style={{outline : "10px solid #F3F8FF", background : "#F3F8FF"}}><div
										  style={{display : "flex"}}
										 >
											<button className="button btn btn-default" disabled={idleLocked} type="button"
											  style={{display : "table-column"}} onClick=
											  {
												() => showDetailPrompt
												({
													...defaultNewLevel, course              : record.data,
													course_id                               : record.data.id,
													session_min_hours                       : record.session_min_hours || 1,
													week_hours                              : record.week_hours        || 1,
													week_min_days                           : record.week_min_days     || 1,
													week_max_days                           : record.week_max_days     || 1,
													subscription_base_price_mxn             : record.subscription_base_price_mxn || 0,
													subscription_base_price_hnl             : record.subscription_base_price_hnl || 0,
													subscription_base_price_usd             : record.subscription_base_price_usd || 0,
													monthly_billing_base_price_mxn          : record.monthly_billing_base_price_mxn || 0,
													monthly_billing_base_price_hnl          : record.monthly_billing_base_price_hnl || 0,
													monthly_billing_base_price_usd          : record.monthly_billing_base_price_usd || 0,
													subscription_surcharge_base_cost_mxn    : record.subscription_surcharge_base_cost_mxn || 0,
													subscription_surcharge_base_cost_hnl    : record.subscription_surcharge_base_cost_hnl || 0,
													subscription_surcharge_base_cost_usd    : record.subscription_surcharge_base_cost_usd || 0,
													monthly_billing_surcharge_base_cost_mxn : record.monthly_billing_surcharge_base_cost_mxn || 0,
													monthly_billing_surcharge_base_cost_hnl : record.monthly_billing_surcharge_base_cost_hnl || 0,
													monthly_billing_surcharge_base_cost_usd : record.monthly_billing_surcharge_base_cost_usd || 0,
													second_surcharge_base_cost_mxn          : record.second_surcharge_base_cost_mxn || 0,
													second_surcharge_base_cost_hnl          : record.second_surcharge_base_cost_hnl || 0,
													second_surcharge_base_cost_usd          : record.second_surcharge_base_cost_usd || 0,
													description                             : record.description || ""
												})
											  }
											>
												<CustomIcon name="addLevel" />
											</button>
											<button disabled={idleLocked} className="button btn btn-default"
											  type="button" onClick={() => showPrompt(record.data)} style=
											  {{
												display    : "table-column",
												color      : "var(--txt-color)",
												paddingTop : "10px"
											  }}
											>
												<FontAwesomeIcon icon={solid("eye")} flip="horizontal"
												 
												/>
											</button>
											{
												!allowed.delete ? "" : <button className="button btn btn-default" disabled={idleLocked}
												  onClick={() => confirmRemoval(record.data.id)} type="button"
												  style={{display : "table-column"}}
												>
													<CustomIcon name="bTrash" />
												</button>
											}
										</div></td>
									</tr>
									{
										record.data.levels && record.data.levels.length > 0 &&
										<tr><td colSpan={11} className="notNow" style=
										  {{
											outline     : "10px solid var(--main-bg-color)",
											background  : "var(--main-bg-color)",
											paddingLeft : "25px"
										  }}
										>
											<label style={{position : "relative", color : "var(--main-txt-color)"}}>
												&emsp;
												Módulos:
											</label>
											<table style={{width : "100%"}} className="specialTable">{
												record.data.levels.map
												(
													(detail : any) => <React.Fragment key={"SCH_" + detail.id}>
														<tbody><tr>
															<td style={{width : "100%"}}><div className="row">
																<div className="col-sm-6">
																	<b>
																		[{detail.code}]
																	</b>
																	&nbsp;
																	{detail.name}
																</div>
																<div className="col-sm-6"><label style=
																  {{
																	color    : "--var(main-txt-color)",
																	position : "relative"
																  }}
																	>
																	{detail.description}
																</label></div>
															</div></td>
															<td>
																{detail.status.name}
															</td>
															<td><div style={{display : "flex"}}>
																{
																	(
																		periods.length > 0 &&
																		(
																			currentUserCampus &&
																			classroomCount > 0
																		)
																	) &&
																	<button
																	  className="button btn btn-default"
																	  type="button"
																	  disabled={idleLocked} style={{display : "table-column"}}
																	  onClick=
																	  {
																		() => showSubDetailPrompt
																		({
																			...defaultNewSchedule, level   : detail,
																			level_id                       : detail.id,
																			course                         : record.data,
																			course_id                      : record.data.id,
																			subscription_price             : detail["subscription_base_price" + suffix],
																			monthly_billing_price          : detail["monthly_billing_base_price" + suffix],
																			subscription_surcharge_cost    : detail["subscription_surcharge_base_cost" + suffix],
																			monthly_billing_surcharge_cost : detail["monthly_billing_surcharge_base_cost" + suffix],
																			second_surcharge_cost          : detail["second_surcharge_base_cost" + suffix]
																		})
																	  }
																	>
																		<CustomIcon name="addSchedule" />
																	</button>
																}
																<button type="button" disabled={idleLocked} onClick=
																  {
																	() => showDetailPrompt
																	({
																		...detail, course : record.data,
																		course_id         : record.data.id,
																		session_min_hours : record.session_min_hours || 1,
																		week_hours        : record.week_hours        || 1,
																		week_min_days     : record.week_min_days     || 1,
																		week_max_days     : record.week_max_days     || 1,
																		mentors           : detail.mentors.map
																		(
																			(mentor : any)=>
																			({
																				...mentor, stocked  : true,
																				deletion            : false,
																				refIndex            : "M_" + mentor.id,
																			})
																		),
																		kits : detail.kits.map
																		(
																			(kit : any)=>
																			({
																				...kit, stocked : true,
																				deletion        : false,
																				refIndex        : "K_" + kit.id,
																			})
																		),
																		assignments : detail.assignments.map
																		(
																			(assignment : any)=>
																			({
																				...assignment, stocked : true,
																				deletion               : false,
																				refIndex               : "A_" + assignment.id,
																				points                 : assignment.pivot.available_points,
																				availabilityStartValue : new Date(assignment.pivot.availability_start + " 23:00:00"),
																				availabilityEndValue   : new Date(assignment.pivot.availability_end + " 23:00:00"),
																			})
																		)
																	})
																  }
																  className="button btn btn-default"
																  style=
																  {{
																	display    : "table-column",
																	color      : "var(--txt-color)",
																	paddingTop : "10px"
																  }}
																>
																	<FontAwesomeIcon flip="horizontal" icon={solid("eye")} />
																</button>
																{
																	!levelAllowance.delete ? "" : <button disabled={idleLocked}
																	  onClick={() => confirmDetailRemoval(record.data.id, detail.id)}
																	  style={{display : "table-column"}} type="button"
																	  className="button btn btn-default"
																	>
																		<CustomIcon name="bTrash" />
																	</button>
																}
															</div></td>
														</tr></tbody>
														{
															detail.schedules.length < 1 ? "" : <tbody className="schedule">
																<tr><td colSpan={3}><fieldset>
																	<legend>
																		Horarios
																	</legend>
																	<table style={{width : "100%"}}><tbody>{
detail.schedules.map
(
	(subDetail : any) => <tr key={"s" + subDetail.id}>
		<td style={{width : "150px"}}
		  ref={el => {if(el)el.style.setProperty("border-bottom", "1px solid #C7C7C7", "important")}}
		>
			[{subDetail.code}]
		</td>
		<td style={{background : "#F3F8FF", width : "150px"}}><div style={{padding : "5px"}}>{
			subDetail.weekSessions.map
			(
				(session : any)=>
				{
					return <div key={"S_" + session}>{session}</div>
				}
			)
		}</div></td>
		<td style={{paddingLeft : "25px"}}>
			{subDetail.name}
		</td>
		<td>
			{subDetail.status.name}
		</td>
		<td>
			{subDetail.period.name}
		</td>
		<td>
			{subDetail.classroom.building.name}
		</td>
		<td>
			{subDetail.classroom.name}
		</td>
		<td><div style={{display : "flex", float : "right"}}>
			{
				(!enrollmentAllowance.read || studentsCount < 1) ? "" : <button
				  className="button btn btn-default"
				  type="button"
				  disabled={idleLocked}
				  onClick={() => promptEnrolling(record.data, detail, subDetail)}
				>
					<CustomIcon name="student" style={{fill : subDetail.enrolled_students > 0 ? "#349EFF" : "#000000"}}/>
				</button>
			}
			<button style={{color : "var(--txt-color)", paddingTop : "10px"}} className="button btn btn-default" type="button"
			  disabled={idleLocked} onClick=
			  {
				() => showSubDetailPrompt
				({
					...subDetail, level : detail,
					level_id            : detail.id,
					course              : record.data,
					course_id           : record.data.id,
					notes               : subDetail.notes || "",
					building_id         : subDetail.classroom.building.id,
					campus_id           : subDetail.classroom.building.campus_id,
					startValue          : new Date(subDetail.start_date + " 23:00:00"),
					endValue            : new Date(subDetail.end_date + " 23:00:00")
				})
			  }
			>
				<FontAwesomeIcon flip="horizontal" icon={solid("eye")} />
			</button>
			{
				!scheduleAllowance.delete ? "" : <button disabled={idleLocked || subDetail.enrolled_students > 0} type="button"
				  title={subDetail.enrolled_students > 0 ? "Debe remover los alumnos del curso antes de poder eliminarlo" : ""}
				  className="button btn btn-default" onClick={() => confirmSubDetailRemoval(subDetail)}
				>
					<CustomIcon name="bTrash" />
				</button>
			}
		</div></td>
	</tr>
)
															}</tbody></table></fieldset>
															</td></tr></tbody>
														}
													</React.Fragment>
												)
											}</table>
									</td></tr>
								}
							</tbody>
							)
						}
					</table>
				}
			</>
		</SectionContainer>
		{
			selectedCourse == null ? "" : <DataDisplayModal editableData={editableCourse} promptCloseFn={closePrompt}
			  headIcon={<FontAwesomeIcon icon={solid("calendar-check")} />} dataEditInit={editCourse} entityName="Curso"
			  uneditFn={unedit} unchangedRecord={unchangedRecord} processingError={saveError} unfilledFields={unfilledFields}
			  saveDataFn={saveRecord} saveLock={state.isButtonDisabled} allowedUpdate={allowed.update} UIStatus={UIStatus}
			  editableRecord={editableCourse} form={recordFormRef} saveSuccess={saveSuccess}
			><form ref={recordFormRef} className="form"><div className="container" onSubmit=
			  {
				(event : FormEvent)=>
				{
					event.preventDefault()
					saveRecord()
				}
			  }
			><div className="row">
				<div className="col-md-4 text-center">
					<div style={{maxWidth : "250px", maxHeight : "250px", display : "inline-block"}}>
						<img src=
						  {
							(editableCourse == null ? selectedCourse.imagePreview : editableCourse.imagePreview)
								|| defaultCourseImage
						  }
						  style=
						  {{
							width    :  "100%", borderRadius :  "100%", margin    :  "auto",
							maxWidth : "250px", maxHeight    : "250px", minHeight : "125px",
							minWidth : "125px", border       : "3px solid #8F91DA"
						  }}
						/>
						{
							editableCourse == null ? "" : <>
								<input onChange={imageLoad} style={{display : "none"}} type="file" id="image"
								  accept="image/*" ref={imageRef} name="image"
								/>
								{
									editableCourse.image == null ? "" :  <button className="btn btn-danger rounded-pill"
									  disabled={idleLocked} onClick={imageDisposal} type="button" style=
									  {{
										left   :  "15%",
										bottom : "40px", position : "relative",
									  }}
									>
										<FontAwesomeIcon icon={solid("times")} size="2x" fill="unset"/>
									</button>
								}
								<button onClick={() => buttonClickHandling(imageRef)} disabled={idleLocked}
								  className="btn rounded-pill" type="button" style=
								  {{
									left       : "16%"    , border   : "1px solid var(--main-bg-color)",
									background : "#C7C7C7", color    : "var(--secondary-txt-color)",
									height     : "56px"   , position : "relative", bottom : "40px"
								  }}
								>
									<FontAwesomeIcon icon={solid("camera")} style={{color : "#000000"}}
									  size="2x" fill="unset"
									/>
								</button>
							</>
						}
					</div>
					<div className="selectContainer">
						<label htmlFor="status_id">
							Estatus
						</label>
						{
							editableCourse == null
								?
							<div className="roView">
								<br />
								{selectedCourse.status.name}
							</div>
								:
							<>
								<select className="btn btn-primary text-start" value={editableCourse.status_id} name="status_id"
								  disabled={idleLocked} onChange={handleRegistryChange} id="status_id"
								  style={{height : "30px"}}
								>{
									courseStatuses.map
									(
										(record : any, i : any) => <option key={i} value={record.id}>
											{record.name}
										</option>
									)
								}</select>
								<div className="iconRight" style={{pointerEvents : "none"}}>
									<CustomIcon name="rowDown" />
								</div>
							</>
						}
					</div>
				</div>
				<div className="col-md-8"><div className="row">
					<div className="col-md-6"><div className="inputContainer">
						<label htmlFor="name">
							{editableCourse == null ? "" : "*"}
							Nombre
						</label>
						{
							editableCourse == null
								?
							<div className="roView">
								<br />
								{selectedCourse.name}
							</div>
								:
							<input onBlur={validateField} disabled={idleLocked} id="name"
							  onFocus={validateField} maxLength={50} placeholder="Nombre"
							  onChange={handleRegistryChange} value={editableCourse.name} type="text"
							  ref={nameRef} name="name" style={{width : "100%"}} required
							/>
						}
					</div></div>
					<div className="col-md-6"><div className="inputContainer">
						<label htmlFor="code">
							{editableCourse == null ? "" : "*"}
							Código
						</label>
						{
							editableCourse == null
								?
							<div className="roView">
								<br />
								{selectedCourse.code}
							</div>
								:
							<input type="text" ref={codeRef} name="code" style={{width : "100%"}} value={editableCourse.code}
							  maxLength={50} onBlur={validateField} disabled={idleLocked}
							  onFocus={validateField} id="code" placeholder="Código"
							  onChange={handleRegistryChange} required
							/>
						}
					</div></div>
					<div className="col-lg-6"><div className="selectContainer">
						<label htmlFor="category_id">
							Modalidad
						</label>
						{
							editableCourse == null
								?
							<div className="roView">
								<br />
								{selectedCourse.mode?.name || "-"}
							</div>
								:
							<>
								<select onChange={handleRegistryChange} value={editableCourse.mode_id}
								  id="mode_id" name="mode_id" className="btn btn-primary text-start" style={{height : "30px"}}
								  disabled={idleLocked}
								>{
									courseModes.map
									(
										(record : any, i : any)=> <option key={i} value={record.id}>
											{record.name}
										</option>
									)
								}</select>
								<div className="iconRight" style={{pointerEvents : "none"}}>
									<CustomIcon name="rowDown" />
								</div>
							</>
						}
					</div></div>
					<div className="col-lg-6"><div className="selectContainer">
						<label htmlFor="category_id">
							Categoría
						</label>
						{
							editableCourse == null
								?
							<div className="roView">
								<br />
								{selectedCourse.category.name}
							</div>
								:
							<>
								<select value={editableCourse.category_id} style={{height : "30px"}} disabled={idleLocked}
								  name="category_id" onChange={handleRegistryChange} id="category_id"
								  className="btn btn-primary text-start"
								>{
									categories.map
									(
										(record : any, i : any) => <option key={i} value={record.id}>
											{record.name}
										</option>
									)
								}</select>
								<div className="iconRight" style={{pointerEvents : "none"}}>
									<CustomIcon name="rowDown" />
								</div>
							</>
						}
					</div></div>
					<div className="card card-default" style={{padding : "15px"}}>
						<div className="underlineHeadingContainer" style={{marginBottom : "unset"}}><Popover positions={["bottom"]}
						  isOpen={noTagsProvided} content=
						  {<div style={{background : "var(--main-bg)", padding : "5px 5px 0px 5px"}}>
							<div className="requirementAlert">
								<FontAwesomeIcon icon={solid("exclamation")}/>
							</div>
							&nbsp;
							Requerido: Elija al menos una opción
						  </div>}
						><div>
							{editableCourse == null ? "" : "*"}
							Etiquetas
						</div></Popover></div>
						{
							editableCourse == null
								?
							<div style={{textAlign : "left", display : "flex", flexFlow : "wrap"}}>{
								selectedCourse.tags.length < 1 ? "(Ninguna)" : selectedCourse.tags.map
								(
									(record : any, index: number) => <div className="badge rounded-pill bg-primary prompt"
									  key={"cr_" + record.id} style=
									  {{
										display : "inline-block",
										padding : "5px",
										margin : "5px"
									  }}
									>
										{record.name}
									</div>
								)
							}</div>
								:
							<>
								<label htmlFor="tags" style={{position : "unset"}}>
									Actualice
								</label>
								<Multiselect onRemove={(tags) => setEditableCourse({...editableCourse, tags : tags})}
								  emptyRecordMsg="No hay más opciones" selectedValues={editableCourse.tags} displayValue="name"
								  options={basicInfo.tags} onSelect={(tags) => setEditableCourse({...editableCourse, tags : tags})}
								  placeholder="Seleccione..."
								/>
							</>
						}
					</div>
					{
						(editableCourse == null && isEmpty(selectedCourse.description)) ? "" : <div
						  style={{padding : "15px 0px 15px 15px"}}
						>
							<UnderlineHeading name={"Descripción"} />
							{
								editableCourse == null ? selectedCourse.description : <textarea ref={descriptionRef}
								  name="description" value={editableCourse.description} className="notesField form-control"
								  onChange={handleRegistryChange} id="description" maxLength={255}
								  disabled={idleLocked} onBlur={validateField}
								/>
							}
						</div>
					}
				</div></div></div>
		</div></form></DataDisplayModal>}
		{
			selectedLevel == null ? "" : <LevelPrompt initAssignments={initAssignments} closeFn={() => setSelectedLevel(null)}
			  levelStatuses={levelStatuses} initKits={initKits} initMentors={initMentors} selectedLevel={selectedLevel}
			  allowedUpdate={levelAllowance.update} afterCloseFn=
			  {
				()=>
				{
					setCustomSearch(defaultSearch)
					search()
				}
			  }
			/>
		}
		{
			selectedSchedule == null ? "" : <SchedulePrompt selectedSchedule={selectedSchedule} buildingRef={buildingRef}
			  closeFn={() => setSelectedSchedule(null)} scheduleStatuses={scheduleStatuses} periods={periods}
			  allowedUpdate={scheduleAllowance.update} currentDate={currentDate} afterCloseFn=
			  {
				()=>
				{
					setCustomSearch(defaultSearch)
					search()
				}
			  }
			/>
		}
		{
			selectedEnrollment == null || selectedUser ? "" : <EnrollmentPrompt closeFn={() => setSelectedEnrollment(null)}
			  allowance={enrollmentAllowance} profileViewingFn={setSelectedUser} selectedEnrollment={selectedEnrollment}
			  afterCloseFn=
			  {
				()=>
				{
					setCustomSearch(defaultSearch)
					search()
				}
			  }
			/>
		}
		{
			selectedUser == null ? "" : <UserPrompt promptCloseFn={()=> setSelectedUser(null)} selectedUser={selectedUser}
			  title="Alumno"
			/>
		}
	</>
}

export default Courses
