import React, {
    useEffect,
    useState
} from "react";
import { useForm } from "react-hook-form";
import { default as SelectComponent } from "react-select";
import Constants, {
    ENROLLMENT_STATUS,
    owedTuitionStatus,
    owedTuitionType,
    TUITION_PROGRESS_STATUS
} from "../../../constants";
import {
    useDispatch,
    useSelector
} from "react-redux";
import {
    AppDispatch,
    disableIdleLockSwitch,
    displayNotice,
    enableIdleLockSwitch,
    RootState
} from "../../../globals";
import HttpManager from "../../../services/HttpManager";
import { getErrorMessage } from "../../../common";
import { Column } from "../../../components/commonTable";
import CustomIcon from "../../../components/customIcon";
import { styles } from "./style";
import {
    handleEnrollmentTuitionSelection,
    setCheckedTuitions,
    setCourse,
    setDisabledTuitions,
    setSelectedTuitions,
    setTuitions
} from "../../../redux/reducers/scheduleReducer";
import { enrollmentStatusType } from "../../../types";
import AccountStatementCard from "../../../components/accountStatementCard";
import { TuitionDTO } from "../../../models/TuitionDTO";

export interface UpdateStudentEnrollmentProps extends React.HTMLAttributes<HTMLDivElement> {
    student: any;
    enrollmentId: number;
    /**
     * 
     * @function to Handle success payment
     */
    onSuccess: () => void
}

interface UpdateStudentEnrollmentFormData {
    statusId: enrollmentStatusType,
    notes: string;
    latestTuitionId: number;
}

const UpdateStudentEnrollmentComponent: React.FC<UpdateStudentEnrollmentProps> = ({
    student,
    enrollmentId,
    onSuccess
}) => {
    const http = HttpManager.getInstance();
    const dispatch = useDispatch<AppDispatch>();
    const currentCampus: any = useSelector((state: RootState) => state.rootReducer.sessionState.currentCampus);
    const enrollmentStatusOptions: any[] = useSelector((state: RootState) => state.rootReducer.catalog.enrollmentStatuses);
    const idleLocked: boolean = useSelector((state: RootState) => state.idleLockSwitch.value);
    const tuitions: any = useSelector((state: RootState) => state.rootReducer.schedule.tuitions);
    const course: any = useSelector((state: RootState) => state.rootReducer.schedule.course);
    const disabledTuitions: any = useSelector((state: RootState) => state.rootReducer.schedule.disabledTuitions);
    const selectedTuitions: any = useSelector((state: RootState) => state.rootReducer.schedule.selectedTuitions);
    const checkedTuitions: any = useSelector((state: RootState) => state.rootReducer.schedule.checkedTuitions);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [selectedEnrollmentStatus, setSelectedEnrollmentStatus] = useState<any>(null);
    const [selectAllTuitions, setSelectAllTuitions] = useState<Set<number>>(new Set<number>());
    const [enrollmentOptions, setEnrollmentOptions] = useState<any[]>(enrollmentStatusOptions);
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors }
    } = useForm<UpdateStudentEnrollmentFormData>();

    const tuitionColumns: Column[] = [
        {
            id: "selectTuition", header: "", accessor: "",
            render: (data: TuitionDTO) => data.statusId === 2 ? <></>
                : <button className="btn btn-default" type="button"
                    disabled={idleLocked}
                >
                    <CustomIcon name="bCheck" />
                </button>,
            overrideCellStyle: () => ({
                borderBottom: 0
            })
        },
        {
            id: "id", header: "", accessor: "id",
            overrideCellStyle: () => ({
                borderBottom: 0
            })
        },
        {
            id: "monthNumber",
            header: Constants.Common.concept,
            accessor: "monthNumber"
        },
        {
            id: "dueDate",
            header: Constants.Common.endDate,
            accessor: "dueDate"
        },
        {
            id: "billinStatus",
            header: Constants.Common.billingStatus,
            accessor: "",
            render: (data: TuitionDTO) => <span
                style={{
                    color: data.isExpired && (
                        (
                            data.statusId === owedTuitionStatus.PENDING
                            || data.statusId === owedTuitionStatus.PARTLY_PAID
                        ) &&
                        (
                            data.enrollmentStatusId != ENROLLMENT_STATUS.ERROR
                            && data.enrollmentStatusId != ENROLLMENT_STATUS.NOT_STARTED
                        )
                    )
                        ? Constants.Styles.text.colors.red
                        : Constants.Styles.text.colors.blackPrimary,
                    font: Constants.Styles.text.fonts.robotoBold16,
                    marginBottom: 0
                }}>
                {
                    data.isExpired && (
                        (
                            data.statusId === owedTuitionStatus.PENDING
                            || data.statusId === owedTuitionStatus.PARTLY_PAID
                        ) &&
                        (
                            data.enrollmentStatusId != ENROLLMENT_STATUS.ERROR
                            && data.enrollmentStatusId != ENROLLMENT_STATUS.NOT_STARTED
                        )
                    ) ? `Vencida/${data.status}` : data.status
                }
            </span>
        },
        {
            id: "progressStatus",
            header: Constants.Common.progressStatus,
            accessor: "",
            render: (data: TuitionDTO) => <span
                style={{
                    marginBottom: 0,
                    font: Constants.Styles.text.fonts.robotoBold16,
                    color: data.progressStatusId === TUITION_PROGRESS_STATUS.NO_COMPLETED
                        ? Constants.Styles.text.colors.red
                        : Constants.Styles.text.colors.blackPrimary,
                }
                }>
                {data.progressStatus}
            </span>,
            overrideCellStyle: () => ({
                textAlign: "center"
            })
        },
        {
            id: "totalAmount",
            header: Constants.Common.totalAmount,
            accessor: "",
            render: (data: TuitionDTO) => data.subTotal.moneyFormat,
            overrideCellStyle: () => ({
                textAlign: "right"
            })
        },
        {
            id: "paidAmount",
            header: Constants.Common.paidAmount,
            accessor: "",
            render: (data: TuitionDTO) => data.paidAmount.moneyFormat,
            overrideCellStyle: () => ({
                textAlign: "right"
            })
        },
        {
            id: "pendingAmount",
            header: Constants.Common.pendingAmount,
            accessor: "",
            render: (data: TuitionDTO) => data.pendingAmount.moneyFormat,
            overrideCellStyle: () => ({
                textAlign: "right"
            })
        }
    ];

    const onError = (message: string) => {
        dispatch(displayNotice({
            cornerClose: false,
            message: message,
            heading: <h3 style={{
                color: "#FF0000",
                display: "inline-block"
            }}>
                Error
            </h3>
        }));
    }

    const showSuccessMessage = () => {
        dispatch(displayNotice({
            cornerClose: false,
            message: "Información procesada con éxito",
            heading: <h3 style={{
                color: "#00FF00", display: "inline-block"
            }}>
                Listo
            </h3>
        }));
    }

    const toggleTuitionsSelection = (tuition: any) => {
        dispatch(handleEnrollmentTuitionSelection(tuition));
    }

    const tuitionColumnsWithLogic = tuitionColumns.map((column: any) => {
        if (column.id === "selectTuition" && column.render) {
            return {
                ...column,
                render: (tuition: any) => (
                    <button
                        className="btn btn-default"
                        type="button"
                        disabled={idleLocked || Array.from(disabledTuitions).includes(tuition.id)}
                        onClick={() => toggleTuitionsSelection(tuition)}
                    >
                        <CustomIcon name={Array.from(checkedTuitions).includes(tuition.id) ? "bCheck" : "bUncheck"} />
                    </button>
                ),
            };
        }
        return column;
    });

    const handleEnrollmentStatusChange = (statusId: enrollmentStatusType) => {
        dispatch(setSelectedTuitions([]));
        dispatch(setCheckedTuitions(new Set<number>()));
        switch (statusId) {
            case ENROLLMENT_STATUS.COMPLETED:
            case ENROLLMENT_STATUS.ERROR:
            case ENROLLMENT_STATUS.NOT_STARTED:
                dispatch(setDisabledTuitions(selectAllTuitions));
                dispatch(setCheckedTuitions(selectAllTuitions));
                dispatch(setSelectedTuitions(tuitions));
                break;
            case ENROLLMENT_STATUS.CHANGE:
            case ENROLLMENT_STATUS.RETIRED:
            case ENROLLMENT_STATUS.POSTPONED:
            case ENROLLMENT_STATUS.SPECIALIZATION:
                dispatch(setDisabledTuitions(new Set<number>()));
                break;
            default:
                dispatch(setDisabledTuitions(new Set<number>()));
                break;
        }
    }

    const showSelectTuitionMessage = () => {
        return (selectedEnrollmentStatus?.id >= ENROLLMENT_STATUS.CHANGE
            || selectedEnrollmentStatus?.id <= ENROLLMENT_STATUS.SPECIALIZATION)
            && selectedTuitions.length === 0
    }

    const onSubmitEnable = () => {
        return idleLocked
            || !course?.enrollmentCanBeUpdated
            || selectedTuitions.length === 0
    }

    const getEnrollmentDetails = async () => {
        try {
            setIsLoaded(true);
            dispatch(enableIdleLockSwitch());
            const response = await http.financesService.getEnrollmentDetails(
                currentCampus.id,
                student.id,
                enrollmentId
            );
            const data = response.data;
            const owedTuitions = response.data.tuitions
                .filter((tuition: any) => tuition.typeId === owedTuitionType.MONTHLY_FEE);
            const course = { ...data, tuitions: owedTuitions }
            handleEnrollmentStatusOptions(course);
            dispatch(setTuitions(owedTuitions));
            dispatch(setCourse(course));
            const allTuitionIds = new Set<number>(owedTuitions.map((t: any) => t.id));
            setSelectAllTuitions(allTuitionIds);
            dispatch(setDisabledTuitions(allTuitionIds));
        } catch (error) {
            console.log(error);
            onError(getErrorMessage(error));
        } finally {
            setIsLoaded(false);
            dispatch(disableIdleLockSwitch());
        }
    }

    const confirmUpdateStudentEnrollment = (data: UpdateStudentEnrollmentFormData) => {
        dispatch(displayNotice({
            cornerClose: true,
            message: `Esta acción es irreversible, 
            ¿Desea ACTUALIZAR La matrícula del estudiante ${student.firstName} ${student.lastName} con correlativo ${student.identityCode}
            de ${course.scheduleStatus} a ${selectedEnrollmentStatus.label}?`,
            heading:
                <h3 style={{
                    color: "#0000FF",
                    display: "inline-block"
                }}>
                    Confirme
                </h3>,
            procedure: () => { updateStudentEnrollment(data) }
        }));
    }

    const updateStudentEnrollment = async (data: UpdateStudentEnrollmentFormData) => {
        try {
            const tuitionIds = selectedTuitions
                .map((tuition: any) => tuition.id)
                .sort((a: number, b: number) => b - a);
            const latestTuitionId = tuitionIds[0] ?? 0;
            const payload = {
                ...data,
                latestTuitionId,
                statusId: selectedEnrollmentStatus.id
            };
            dispatch(enableIdleLockSwitch());
            const response = await http.enrollmentService.updateStudentEnrollment(
                student.id,
                enrollmentId,
                payload
            )
            showSuccessMessage();
            reset();
            onSuccess();
        } catch (error) {
            console.log(error);
            onError(getErrorMessage(error));
        } finally {
            dispatch(disableIdleLockSwitch());
        }
    }

    const handleEnrollmentStatusOptions = (course: any) => {
        const options = course.isScheduleFinished ? enrollmentStatusOptions
            : enrollmentStatusOptions
                .filter((status: any) => status.id !== ENROLLMENT_STATUS.COMPLETED)
        setEnrollmentOptions(options);
    }

    useEffect(() => {
        getEnrollmentDetails();
    }, [enrollmentId]);

    useEffect(() => {
        if (selectedEnrollmentStatus) {
            handleEnrollmentStatusChange(selectedEnrollmentStatus.id);
        } else {
            dispatch(setDisabledTuitions(selectAllTuitions));
        }
    }, [selectedEnrollmentStatus]);

    return isLoaded ? <div style={styles.loadingContainer}>
        <h3>
            Cargando ...
        </h3>
    </div> : (
        <div style={styles.updateEnrollmentContainer}>
            <div style={styles.studentDataRow}>
                <div style={styles.subtitleContainer}>
                    <label style={styles.studentInfoLabel}>
                        {Constants.Common.name}:
                    </label>
                    <span style={styles.studentDataText}>
                        {student.firstName}
                    </span>
                </div>
                <div style={styles.subtitleContainer}>
                    <label style={styles.studentInfoLabel}>
                        {Constants.Common.lastName}:
                    </label>
                    <span style={styles.studentDataText}>
                        {student.lastName}
                    </span>
                </div>
                <div style={styles.subtitleContainer}>
                    <label style={styles.studentInfoLabel}>
                        {Constants.Common.correlative}:
                    </label>
                    <span style={styles.studentDataText}>
                        {student.identityCode}
                    </span>
                </div>
            </div>
            <form onSubmit={handleSubmit(confirmUpdateStudentEnrollment)}
                className="form"
                style={styles.updateEnrollmentForm}>
                <div style={styles.enrollmentOptionsContainer}>
                    <div style={styles.enrollmentOptionsSubContainer}>
                        <label htmlFor="name"
                            style={styles.smallGrayText}>
                            {`${Constants.Common.updateEnrollment}*`}
                        </label>
                        <div style={styles.selectContainer}>
                            <SelectComponent
                                id="statusId"
                                {
                                ...register('statusId',
                                    {
                                        required: 'Seleccione un valor.'
                                    }
                                )}
                                options={enrollmentOptions}
                                isDisabled={idleLocked || !course?.enrollmentCanBeUpdated}
                                onChange={(value) => {
                                    setSelectedEnrollmentStatus(value);
                                    setValue('statusId', value.id);
                                }}
                            />
                            {errors.statusId &&
                                <p style={{
                                    ...styles.errorMessage
                                }}>
                                    {errors.statusId.message}
                                </p>
                            }
                        </div>
                    </div>
                </div>
                {course && course?.tuitions.length > 0 ?
                    <div className="row"
                        style={styles.tuitionsTableContainer}>
                        <div className="col-lg-12"
                            style={styles.tableContainer}>
                            <AccountStatementCard
                                key={1}
                                columns={tuitionColumnsWithLogic}
                                data={course}
                            />
                        </div>
                    </div>
                    : <React.Fragment />
                }
                <div style={styles.saveChangesContainer}>
                    {showSelectTuitionMessage() &&
                        <p style={{
                            ...styles.errorMessage
                        }}>
                            {Constants.validations.selectTuition}
                        </p>
                    }
                    <div style={{
                        width: "100%",
                        padding: "10px"
                    }}>
                        <label htmlFor="notes"
                            style={{
                                position: "relative"
                            }}>
                            {Constants.Common.notes}
                        </label>
                        <div style={{
                            wordBreak: "break-all",
                            textOverflow: "unset"
                        }}>
                            <textarea id="notes"
                                {
                                ...register('notes',
                                    {
                                        required: 'El campo notas es requerido.',
                                        minLength: {
                                            value: 10,
                                            message: 'La nota debe tener al menos 10 caracteres'
                                        }
                                    }
                                )}
                                placeholder={Constants.Common.notes}
                                name="notes"
                                style={{
                                    resize: "vertical",
                                    width: "100%",
                                    border: "1px solid #7678BF",
                                    borderRadius: "5px",
                                    minHeight: "150px",
                                    padding: "5px"
                                }}
                            />
                            {errors.notes &&
                                <p style={{
                                    ...styles.errorMessage
                                }}>
                                    {errors.notes.message}
                                </p>
                            }
                        </div>
                    </div>
                    <button className="btn btn-outline-danger rounded-pill"
                        type="submit"
                        disabled={onSubmitEnable()}
                        style={styles.saveChangesBtn}>
                        {Constants.Common.saveChanges}
                    </button>
                </div>
            </form>
        </div>
    )
}

export default UpdateStudentEnrollmentComponent;